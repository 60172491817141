<template>
  <div style="overflow:visible" class="right-content px-4">
    <!-- <el-tooltip content="Show Guide Line" placement="top" effect="light" v-if="!showEvent">
      <el-button icon="el-icon-question" class="btn-guide--line" type="info" size="default" circle
        @click="showGuide()"></el-button>
    </el-tooltip> -->
    <template>
      <div class="mid--page_container" style="display:flex;justify-content:space-between;width:94%;align-items:center">
        <div class="mid--page my-3">
            
            <div class="c--breadcrumb" style="width: 100%;margin-left:0px">
        <router-link to="/">
          <svg style="margin-right: 5px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="home icon">
        <path id="Icon" d="M9 21V14.1528C9 13.5226 9.53726 13.0116 10.2 13.0116H13.8C14.4627 13.0116 15 13.5226 15 14.1528V21M11.3046 3.21117L3.50457 8.48603C3.18802 8.7001 3 9.04665 3 9.41605V19.2882C3 20.2336 3.80589 21 4.8 21H19.2C20.1941 21 21 20.2336 21 19.2882V9.41605C21 9.04665 20.812 8.70011 20.4954 8.48603L12.6954 3.21117C12.2791 2.92961 11.7209 2.92961 11.3046 3.21117Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round"/>
          </g></svg>
          </router-link>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="chevron-double-left icon">
          <path id="Icon" d="M13.4 5.80018L19 11.4002L13.4 17.0002M5 5.80018L10.6 11.4002L5 17.0002" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
        </svg>
        <span class="text-near-arrow" > Projects</span>
      </div>
        </div>
        <div class="flex align-center">
            <h3 class="need-help-text">Need help</h3>
   <el-tooltip content="Show Guide Line" placement="top" effect="light" v-if="!showEvent">
      <el-button style="position:relative !important"  class="btn-guide--line question-icon-custom" type="info" size="default" circle
        @click="showGuide()"></el-button>
    </el-tooltip>
  </div>
      </div>
      <div class="add_project_section">
        <h2 class="page_name">Projects</h2>
        
      </div>
      <div class="logo_section">
        <div style="display:flex">
        <el-input   class="custom-pad" placeholder="Search" v-model="queryProject">
          <i slot="prefix" class="el-input__icon el-icon-search"  style="transform: rotate(0deg) !important;font-size: 20px;margin-left: 12px;"></i>
          </el-input>
            
        </div>
        <div style="display:flex;align-items: center;"><button @click="addTestModalProject" id="addProjectButton" class="add_button">
          <i><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12.0001 4.79999L12 19.2M19.2 12L4.80005 12" stroke="#E6E6E6" stroke-width="2" stroke-linecap="round"/>
</svg></i>
          Add</button></div>
          
      </div>
      <div  class="eff--page" id="listApps">
        <div :class="loadingDetailProject ? `` : `ctb--body`" v-if="loadingProject">
          <div class="ctbb--row mb-2" style="width: 100px !important; height: 100px;">
            <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px; border-radius: 0.25rem;" />
          </div>
        </div>
        <div class="ctb--body" v-else>
          
          <template v-if="toShowProject.length > 0">
            <div v-for="elt in toShowProject" :key="elt.id" class="ctbb--row mb-2 app--animation"
              @click="showComponent(elt)">
              <div class="ctbb--inner" id="cardApp">
                <div  class="elt--banner">
                  <div :id="`${elt.name}`" class="elt--banner_img elt--banner_name">
                    <span class="name">{{ getSlicName(elt.name) }}</span>
                  </div>
                  <!-- <img v-if=" elt.postHog && elt.postHog == true"  src="/favicon.png" alt="Wring" style="width: 30px; position: absolute; top: 5px; right: 10px;"> -->
                  <div :id="`banner_${elt.name}`" class="elt--banner_overlay"></div>
                  <el-alert v-if="elt.isSharedProject && elt.isSharedProject === true"
                    class="no--close elt--banner_overlay_status" show-icon title="Shared with you"
                    type="success"></el-alert>
                  <el-tooltip v-if="elt.creatorEmail" class="item" effect="light" content="Creator" placement="top">
                    <span v-if="elt.creatorEmail && elt.isSharedProject && elt.isSharedProject === true"
                      class="elt--banner_overlay_created">
                      <i class="far fa-user mr-1"></i>
                      {{ elt.creatorEmail }}
                    </span>
                  </el-tooltip>
                </div>
                
                <div class="content-project-card" style="background-color: rgba(25, 27, 54, 0.75);backdrop-filter: blur(2px);">
                  <!-- <div style="width: 100%;height: 100%;background-color: rgba(25, 27, 54, 0.75);backdrop-filter: blur(2px);"></div> -->
                  <div class="elt--content" style="justify-content: center;margin-top: 0px;">
                  <span class="project-name"> {{ elt.name }} </span>
                  </div>
                  <div  class="custom--bell">
                    <el-tooltip :disabled="elt.isSharedProject || elt.isSharedProject === true" class="item mr-3"
                      effect="light" content="Edit this app" placement="top"><button 
                    class="option_project_button" @click.stop="editProjectModal(elt)">
                    <i class="img_option"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M13.4487 6.9516L17.0487 10.5516M4.44873 19.5516L8.81472 18.6719C9.04649 18.6252 9.25931 18.511 9.42645 18.3438L19.2001 8.56478C19.6687 8.09593 19.6684 7.33594 19.1994 6.86748L17.129 4.7994C16.6602 4.33113 15.9006 4.33145 15.4322 4.80011L5.65749 14.5802C5.49068 14.7471 5.37678 14.9595 5.33003 15.1908L4.44873 19.5516Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                  </button></el-tooltip>
                    


                  <!-- <el-tooltip :disabled="elt.isSharedProject || elt.isSharedProject === true" class="item mr-3"
                      effect="light" content="Share App" placement="top"><button 
                    class="option_project_button" @click.stop="showAllSharedUsers(elt.projectId, elt.name)">
                    <i class="img_option"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M21.0002 11.459L11.4002 5.10001L11.4002 8.70001C3 10.5 3 18.9 3 18.9C3 18.9 6.6 14.1 11.4002 14.7L11.4002 18.42L21.0002 11.459Z" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                    </svg></i>
                  </button></el-tooltip> -->


                  <el-tooltip :disabled="elt.isSharedProject || elt.isSharedProject === true" class="item "
                      effect="light" content="Delete this app" placement="top"><button 
                    class="option_project_button"  @click.stop="deleteProject(elt)">
                    <i class="img_option"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M4 6.17647H20M10 16.7647V10.4118M14 16.7647V10.4118M16 21H8C6.89543 21 6 20.0519 6 18.8824V7.23529C6 6.65052 6.44772 6.17647 7 6.17647H17C17.5523 6.17647 18 6.65052 18 7.23529V18.8824C18 20.0519 17.1046 21 16 21ZM10 6.17647H14C14.5523 6.17647 15 5.70242 15 5.11765V4.05882C15 3.47405 14.5523 3 14 3H10C9.44772 3 9 3.47405 9 4.05882V5.11765C9 5.70242 9.44772 6.17647 10 6.17647Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg></i>
                  </button></el-tooltip>
  
                </div>
                </div>
                
              </div>
            </div>
          </template>
        </div>
        <div class="no-items-container" v-if="noItems">
            {{ $t("list_item_page.no_items_found") }}
          </div>
      </div>
    </template>


    <!-- Modal add project start-->
    <div class="add-test-project-modal" >
      <modal class="small-modal" :show.sync="addTestProject" >
        <template slot="header" class="edit-app-modal-header">
          <div class="header-add-test-project">
            <h5 class="modal-title" >
            {{
              editProject == true
              ? " Edit Project " + `"${projectName}"`
              : " Add Project"
            }}
            
          </h5>
                  <button style="margin-right: 5px;"  :disabled="projectName.length === 0 || loadingAddProject" @click="addProject(editProject ? 'edit' : 'add')"  class="add_button">
                    Save
                  </button>
          </div>
          
        </template>
        <div class="body--modale" v-loading="loadingAddProject">
          <div   class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <div :class="editProject ? 'grid_selection' : 'grid_selection_custom'">
                  <div class="section_input">
                <label class="model-label" for="project_name">Name</label>
                <input  type="text" id="project_name" name="project_name" placeholder="App name" v-model="projectName" />
                <label class="model-label mt-3" for="app_urls">URL</label>
                <!-- <div class="ultra--ipt"> -->
                  <div style="display: none;" class="ultra--ipt_content pl-2" ref="data-rendered"></div>
                  <input  type="text"  id="projectUrl" @input="handleUltraIpt" @change="handleUltraIpt"
                    @keyup.backspace="handleDeleteLastDataValue" v-model="ultraIpt" />
                <!-- </div> -->
                <label class="model-label" for="projectTags">Tags</label>
                <div class="ultra--ipt"> 
                  <div style="background-color: #dbdbdb;" class="ultra--ipt_content pl-2" ref="data-rendered-tags"></div>
                    <input style="border-radius: 0; border: none;"  type="text"  id="projectTags" @input="handleUltraIptTag" @change="handleUltraIptTag"
                      @keyup.backspace="handleDeleteLastDataValueTags" v-model="ultraIpt_tags" />
                </div>
                </div>
                <div v-if="editProject == true" class="section_input">
                <label v-if="editProject == true" class="model-label" for="api_Key">
                  API Key
                </label>
                <input type="text" id="api_Key" disabled="true" name="api_Key" placeholder="api Key"
                  v-if="editProject == true" v-model="projectDetail.apiKey" />
                <label v-if="editProject == true" class="model-label mt-3" for="server_Url">
                  Server URL
                </label>
                <input type="text" id="server_Url" disabled="true" name="server_Url" placeholder="server Url"
                  v-if="editProject == true" v-model="projectDetail.serverUrl" />
                  </div>
                  </div>
                <!-- <label v-if="editProject == true" class="model-label" for="server_Url">
                  App Map Setting
                </label> -->
                <div class="container-settings--appmap">
                  <div class="selection_settings">
                    
                  <!-- <label for="key_flow_select" style="white-space: nowrap;" class="medium-text mr-3">Update Map</label>
          
                  <label style="position:relative;bottom:0;margin:0.4em 0 0 0;height:40px;width: 60px;" class="my_switch">
                        <input @input="updateAppmapSettings" v-model="appmapSettings.active" type="checkbox" >
                        <span class="slider round"></span>
                      </label> -->
                  <!-- <el-switch class="custom-switch-round" @input="updateAppmapSettings" style="margin-top: 5px;" v-model="appmapSettings.active" active-color="#1F213E"
                    inactive-color="#759DCB">
                  </el-switch> -->
                </div>

                  <!-- <div class="is--update_days" id="updateDays">
                    <label  class="mr-3 medium-text" for="update_days">
                      Every
                    </label> -->
                    <!-- <input @blur="updateAppmapSettings" type="number" id="update_days" name="update_days" placeholder="Update days"
                      v-model="appmapSettings.updateDays" /> -->
                      <!-- <input class="days-input" type="number" v-model="appmapSettings.updateDays" @blur="updateAppmapSettings" min="0">

                    <label  class="ml-3 medium-text" for="update_days">
                      days
                    </label>
                  </div> -->
                  <div style="left: 0;width: 100%;" v-if="editProject">
              <!-- <el-tooltip :disabled="!(projectName.length === 0)" :content="'app name cannot be empty'" placement="top">
                <span>
                  <button :disabled="projectName.length === 0 || loadingAddProject" @click="addProject('edit')"  class="add_button">
                    Save
                  </button>
                </span>
              </el-tooltip> -->
            </div>
            <div style="left: 0;width: 100%;" v-else>
             
            </div>
                </div>
                <!-- <div v-if="editProject == true" class="snippet--inp">
                  <div class="selection_labels">
                  <label class="model-label" for="js_Snppet"> User Sessions and API snippet</label>
                  
                  <label class="model-sublabel">
                    Use this to enable Analytics and API monitoring</label>
                </div>
                  <i class="copy-icon" @click="copy(projectDetail.jsSnppet, 'js snippet')"></i>
                </div> -->
                <!-- <div v-if="editProject == true" class="script p-4 mb-4">
                  <template>
                    <p class="text-white text-xs" style="overflow: hidden; text-overflow: ellipsis">
                      {{ projectDetail.jsSnppet }}
                    </p>
                  </template>
                </div> -->

                <!-- <div v-if="editProject == true" class="snippet--inp">
                  <div class="selection_labels">
                  <label class="model-label" for="js_Snppet"> User Sessions and API snippet</label>
                  
                  <label class="model-sublabel">
                    Use this to enable Analytics and API monitoring</label>
                </div>
                  <i class="copy-icon" @click="copy(projectDetail.apiSnppet, 'api snippet')"></i>
                </div> -->
                <!-- <div v-if="editProject == true" class="script p-4">
                  <template>
                    <p class="text-white text-xs" style="overflow: hidden; text-overflow: ellipsis">
                      {{ projectDetail.apiSnppet }}
                    </p>
                  </template> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <template slot="footer">
          <div class="add-test-footer-btn" >
            <base-button type="secondary" @click="
              addTestProject = false;
            cleanUpProject();
            ">{{ $t("list_item_page.close") }}</base-button>
          </div>
        </template> -->
      </modal>
    </div>
    <!-- Modal add project end-->

    <!-- Modal detail project start-->
    <div class="show-test-project-modal">
      <modal :show.sync="showDetailProject">
        <template slot="header">
          <h5 class="modal-title" id="showprojectDetailModal">
            {{ projectDetail.name }} App details
          </h5>
          
        </template>
        <div class="body--modale" v-loading="loadingDetailProject">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="project_name"> App Name </label>
                <input type="text" id="project_name" disabled="true" name="project_name" placeholder="App name"
                  v-model="projectDetail.name" />
                <label class="model-label" for="app_urls"> App URLs </label>
                <div class="ultra--ipt">
                  <div class="ultra--ipt_content pl-2" ref="data-rendered-detail"></div>
                  <input type="text" disabled="true" class="ultra--ipt_ipt px-2" @input="handleUltraIpt"
                    @change="handleUltraIpt" @keyup.backspace="handleDeleteLastDataValue" v-model="ultraIpt" />
                </div>
                <label class="model-label" for="project_name">Tags</label>
                <div style="display: none;" class="ultra--ipt_content pl-2" ref="data-rendered-tag-detail"></div>
                  <input  type="text"  id="projectUrl" @input="handleUltraIptTag" @change="handleUltraIptTag"
                    @keyup.backspace="handleDeleteLastDataValueTags" v-model="ultraIpt_tags" />
                <label class="model-label" for="api_Key"> API Key </label>
                <input type="text" id="api_Key" disabled="true" name="api_Key" placeholder="api Key"
                  v-model="projectDetail.apiKey" />
                <label class="model-label" for="server_Url"> Server URL </label>
                <input type="text" id="server_Url" disabled="true" name="server_Url" placeholder="server Url"
                  v-model="projectDetail.serverUrl" />
                <div class="snippet--inp">
                  <label class="model-label" for="js_Snppet"> JS Snippet </label>
                  <i class="ni ni-single-copy-04" @click="copy(projectDetail.jsSnppet, 'js snippet')"></i>
                </div>
                <div class="script p-4">
                  <template>
                    <p class="text-white text-xs" style="overflow: hidden; text-overflow: ellipsis">
                      {{ projectDetail.jsSnppet }}
                    </p>
                  </template>
                </div>

                <div class="snippet--inp">
                  <label class="model-label" for="js_Snppet"> API Snippet </label>
                  <i class="ni ni-single-copy-04" @click="copy(projectDetail.apiSnppet, 'api snippet')"></i>
                </div>
                <div class="script p-4">
                  <template>
                    <p class="text-white text-xs" style="overflow: hidden; text-overflow: ellipsis">
                      {{ projectDetail.apiSnppet }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showDetailProject = false">{{
              $t("list_item_page.close")
            }}</base-button>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal detail project end-->

    <div class="add-test-project-modal">
      <modal class="small-modal" :show.sync="showModalShareProject">
        <template slot="header">
          <h5   class="modal-title" id="addTestModal">
            Share Project access
          </h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="run-name">
              <label class="model-label" for="run_name">
                {{ $t("list_item_page.email_address") }}
              </label>
              <div class="is-flex" style="width: 100%;flex-direction: column;align-items: start;">
                <input type="text" id="email_address" name="run_name" style="width: 100%;"
                  :placeholder="$t('list_item_page.placeholder_email_address')" v-model="emailAddress" />
                <el-tooltip :disabled="!(emailAddress.length === 0 || emailAddress === '')" :content="`${emailAddress === '' ? 'Email cannot be empty' : ''}`
                  " placement="top">
                  <span>
                    <button :disabled="emailAddress.length === 0 || emailAddress === ''" @click="sendEmailAddress" class="add_button">
                      Share
                    </button>
                    
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <!-- <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showModalShareProject = false;
            cleanUpState();">
              X
            </base-button>
          </div>
        </template> -->
        <template>
          <h5 class="modal-title" id="addTestModal" style="margin:24px 0px 4px 0px;">
            People with access
          </h5>
        </template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <data-list :tHeader="tHeaderUsersShared" :loading="loadingSharedUsers" :items="usersShared"
              :customSizes="true" ref="dataListTestSuite">
              <template #content>
                <div class="tblb--row mb-2" v-for="elt in usersShared" :key="elt.id">
                  <div class="elt-row" style="width:40%; text-align: center;margin-left:50px;overflow: visible;">
                      <span style=" color: #ffffff;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;white-space: nowrap;">
                        {{ elt.full_name }}
                      </span>
                    </div>
                  <div style="justify-content: space-around;" class="tblb--inner">
                    <div class="elt-row" style="width:40%;overflow:visible">
                      <span style=" color: #ffffff;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;" class="test-run-name">{{ elt.email }}</span>
                      <br />
                    </div>
                    <div v-if="elt.user_role != 'creator'" class="elt-row" style="width: 20%; text-align: center;">
                      <el-tooltip class="item" effect="light" content="Delete user" placement="top">
                        <el-button type="danger" icon="el-icon-delete" @click.stop=" delteSharedUser(elt.email)" circle
                          plain size="small"></el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </template>
            </data-list>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>

import {
  
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import LeftSideBar from "../../../components/SidebarPlugin/LeftSideBar.vue";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const POSTHOG_MODE = "POSTHOG_MODE"
const WRING_MODE = "WRING_MODE"


export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    DataList,
  },

  data() {
    return {
      createProjectMode: WRING_MODE,
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      Header: [
        {
          label: "App name".toUpperCase(),
          key: "name",
          width: "20%",
        },
        // {
        //   label: "Api key".toUpperCase(),
        //   key: "",
        //   width: "40%",
        //   sortable: false,
        // },
        { label: "".toUpperCase(), key: "", width: "15%", sortable: false },
      ],
      paginationProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      tHeaderUsersShared: [
      {
          label: this.$t("list_item_page.name"),
          key: "runName",
          sortable: true,
          width: "40%"
        },
        {
          label: this.$t("Email"),
          key: "Email",
          sortable: true,
          width: "40%",
        },
        {
          label: "",
          key: "",
          sortable: true,
          width: "20%"
        }
      ],
      showEvent:false,
      noItems: false,
      screeshotsAll:[],
      result: [],
      filteredResults: [],
      resultProject: [],
      filteredResultsProject: [],
      toProject: 0,
      fromProject: 0,
      totalProject: 0,
      loadingProject: true,
      toShowProject: [],
      queryProject: "",
      id: "",
      dataMe: "",
      lastUrlTest: "",
      addTestProject: false,
      appUrls: "",
      tagsProject: "",
      appUrlsLs: [],
      appUrlsLsTags: [],
      loadingAddProject: false,
      editProject: false,
      showDetailProject: false,
      projectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      newProjectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      loadingDetailProject: true,
      ultraIpt: "",
      ultraIpt_tags: "",
      projectName: "",
      showModalShareProject: false,
      showModalSharedUsers: false,
      emailAddress: "",
      usersShared: [],
      loadingSharedUsers: false,
      sharedProjectId: "",
      sharedProjectName: "",
      latestProjects: [],
      appmapSettings: {
        active: true,
        updateDays: 5,
      },
      projectDetail2: {},
      
    };
  },
 
  async created() {
    this.$nextTick(async ()=>{
      console.log(this.loadingProject)
    sessionStorage.getItem("projectPageNbr")
      ? (this.paginationProject.perPage = parseInt(
        sessionStorage.getItem("projectPageNbr")
      ))
      : (this.paginationProject.perPage = 10);
    this.total = this.result.length;
    this.totalProject = this.resultProject.length;
    await this.getProject();
    })
  },
  methods: {
  async getPost(){
   let  message = "hello"
   user_id = '43423423'
   app_id = '123123123'
   session_name = 'r132432rr34r2r3'
     const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/all_messages?message=`;
  },

    getSlicName(name){
     let  tName = name.split(" ")
     let tNameSecond = name.split("")
     if(tName.length > 1){
      return tName[0][0] +  tName[1][0]
     }else if(tNameSecond.length > 1){
      return name[0] +  name[1]
     } else {
      return tNameSecond[0]
     }
    },
    showComponent(elt) {
      if (elt.projectId) {
        let date = new Date()
        let project = { name: elt.name, date: date, projectId: elt.projectId }
        if (localStorage.getItem("latest_projects")) {
          console.log(localStorage.getItem("latest_projects").split(","))
          if (localStorage.getItem("latest_projects").split(",").length < 4) {
            let temp = localStorage.getItem("latest_projects")
            this.latestProjects.push(JSON.parse(temp))
            this.latestProjects.push(project)
          } else {
            let array = localStorage.getItem("latest_projects")
            let array2 = JSON.parse(array)
            let filterDate = []
            console.log(array2)
            array2.map((item) => {
              if (item.date) {
                filterDate.push(item)
              } else {
                filterDate.push(item[0])
              }
            })
            filterDate.sort(function (a, b) {
              return a.date - b.date;
            });
            this.latestProjects.push(project)
            this.latestProjects.push(filterDate[0])
          }
        } else {
          this.latestProjects.push(project)
        }

        localStorage.setItem("latest_projects", JSON.stringify(this.latestProjects))
        const route = {
          name: "List Sessions",
          params: {
            id: elt.projectId,
            name : elt.name
          },
        }

        if (elt.wring)
          route.query = {
            wring: true
          }

        this.$router.push(route);
      }
    },
    remove(i) {
      this.filesList.splice(i, 1);
    },
    restoreAllFiles() {
      this.filesList = this.fileBackup;
    },
    copy(text, type) {
      if (text.length > 0) {
        navigator.clipboard.writeText(text).then(() => {
          this.$notify({
            type: "success",
            message: type + " Copied Successfully.",
          });
        });
      }
    },
    handleUltraIpt() {
      if (this.ultraIpt.length > 0 && this.ultraIpt[0] === " ") {
        this.ultraIpt = "";
      } else if (this.ultraIpt.slice(-1) === " ") {
        const elt = document.createElement("span");
        elt.classList.add("ml-1");
        elt.classList.add("u--tag");
        elt.innerHTML = " " + this.ultraIpt.trim() + " ";
        if (this.editProject === true) {
          this.addUrls(this.ultraIpt);
        }
        this.ultraIpt = "";
        if (this.$refs["data-rendered"].innerHTML.length > 1) {
          const space = document.createElement("span");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered"].appendChild(space);
          this.appUrls = space;
        }
        this.$refs["data-rendered"].appendChild(elt);
        this.appUrls = elt;
      } else if (this.ultraIpt.length > 0 && this.ultraIpt[0] != " ") {
        this.appUrls = this.ultraIpt;
      } else if (this.ultraIpt.length === 0) {
        this.appUrls = "";
      }
    },

    handleUltraIptTag() {
      if (this.ultraIpt_tags.length < 2 && this.ultraIpt_tags[0] === " ") {
        console.log("hunter debug", this.tagsProject)
        this.ultraIpt_tags = "";
      } else if (this.ultraIpt_tags.slice(-1) === " ") {
        const elt = document.createElement("span");
        elt.classList.add("ml-1");
        elt.classList.add("u--tag");
        elt.innerHTML = " " + this.ultraIpt_tags.trim() + " ";
        
        this.ultraIpt_tags = "";
        if (this.$refs["data-rendered-tags"].innerHTML.length > 1) {
          const space = document.createElement("span");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered-tags"].appendChild(space);
          this.tagsProject = space;
        }
        this.$refs["data-rendered-tags"].appendChild(elt);
        this.tagsProject = elt;
      } else if (this.ultraIpt_tags.length > 0 && this.ultraIpt_tags[0] != " ") {
        this.tagsProject = this.ultraIpt_tags;
      } else if (this.ultraIpt_tags.length === 0) {
        this.tagsProject = "";
      }
    },
    handleDeleteLastDataValue() {
      let getStr = this.$refs["data-rendered"].innerHTML
        .replace(/<span class="ml-1 u--tag">/g, "")
        .replace(/<span class="ml-1">/g, "")
        .replace(/<\/span>/g, ",")
        .split(",")
        .filter((s) => s.trim().length > 0)
        .map((s) => s.trim());
      if (this.ultraIpt.length <= 0) {
        if (this.editProject === true) {
          this.deleteUrls(getStr.pop());
        } else {
          let _rem = getStr.splice(-1);
        }
        this.$refs["data-rendered"].innerHTML = "";
        getStr.map((item) => {
          const elt = document.createElement("span");
          elt.classList.add("ml-1");
          elt.classList.add("u--tag");
          elt.innerHTML = " " + item + " ";
          this.$refs["data-rendered"].appendChild(elt);
          this.appUrlsLs.push(elt);
        });
      } else if (this.ultraIpt.length === 0) {
        this.appUrls = "";
      }
    },
    handleDeleteLastDataValueTags() {
      let getStr = this.$refs["data-rendered-tags"].innerHTML
        .replace(/<span class="ml-1 u--tag">/g, "")
        .replace(/<span class="ml-1">/g, "")
        .replace(/<\/span>/g, ",")
        .split(",")
        .filter((s) => s.trim().length > 0)
        .map((s) => s.trim());

      getStr.pop()
      if (this.ultraIpt_tags.length <= 0) {
        this.$refs["data-rendered-tags"].innerHTML = "";
        getStr.map((item) => {
          const elt = document.createElement("span");
          elt.classList.add("ml-1");
          elt.classList.add("u--tag");
          elt.innerHTML = " " + item + " ";
          this.$refs["data-rendered-tags"].appendChild(elt);
          this.appUrlsLsTags.push(elt);
        });
      } else if (this.ultraIpt_tags.length === 0) {
        this.tagsProject = "";
      }
    },
    cleanUpProject() {
      this.projectName = "";
      this.appUrls = "";
    },
    async getAuthMe() {
      let url = `${process.env.VUE_APP_API_BASE_URL}/auth/v1/users/me`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status <= 500);
        };
        const res = await axios.get(url);
        if (res.data.response) {
          console.log("response", res);
          this.dataMe = res.data.response;
        } else {
          //   this.$notify({
          //   type: "danger",
          //   duration: 5000,
          //   message: "No more details available for this state",
          // });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async addTestModalProject() {
      this.addTestProject = true;
      this.appUrls = "";
      this.projectName = "";
      this.projectId = null;
      this.editProject = false;
      this.$refs["data-rendered"].innerHTML = "";
      await this.getDetailProject()
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    async getDetailProject() {
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };

      // check if the project contains mobile screenshots
      const resp = await axios.get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/user-projects/" + this.projectId);
      if (resp.data && resp.data.status === "success") {
        this.projectDetail2 = resp.data.response
        this.appmapSettings.active = this.projectDetail2.attributes.appmapSettings.active
        this.appmapSettings.updateDays = this.projectDetail2.attributes.appmapSettings.updateDays
      }
    },
    async updateAppmapSettings(event) {
      console.log('Change event:', event);
      console.log("prjectID", this.projectId)
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}`;
      this.appmapSettings.updateDays = Number(this.appmapSettings.updateDays)
      let data = {
        appmapSettings: this.appmapSettings,
      };
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.put(url, data);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          return true;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "the update app map Settings has failed.",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    downloadTestFile(index) {
      // console.log("Before downloading file: ", files);
      if (index) {
        const files = this.fileBackup;
        let fileName = files[index].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[index]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        const files = this.filesList;
        let fileName = files[0].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[0]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    savePaginationProject() {
      sessionStorage.setItem("projectPageNbr", this.paginationProject.perPage);
      this.getProject();
    },
    async handleSizeChangeProject(val) {
      this.fromProject = (val - 1) * this.paginationProject.perPage;
      this.toProject =
        parseInt(this.fromProject) + parseInt(this.paginationProject.perPage);
      const sortByMapped = (map, compareFn) => (a, b) => compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsProject = [...this.filteredResultsProject].sort(byPrice);

      this.toShowProject = this.filteredResultsProject;
      this.loadingProject = true;
      this.loadingProject = false;
      // await this.getScreenshots()
    },
    async getScreenshots(){
      try {
      const cachResult = JSON.parse(localStorage.getItem('cachedResults')) || {}
    const promises = this.toShowProject.map(async (project) => {
      const cach = `${project['name']}`
      if(cach in cachResult){
        console.log('cash')
        return  { project_name : project['name'], screenshot : cachResult[cach]};
      }
      console.log("ne cash")
      const resp = await axios.get(`${process.env.VUE_APP_API_URL_PREFIX}/pageviews/appmap?project_id=${project['projectId']}&date_from=2023-08-01`);
      if (resp.data.appmaps && resp.data.appmaps.length > 0) {
        const appmapId = resp.data.appmaps[0]['appmap_id'];
        const url = await axios.get(`${process.env.VUE_APP_API_URL_PREFIX}/pageviews/appmap?project_id=${project['projectId']}&app_map_id=${appmapId}`);
        if (url.data.summary_graph && url.data.summary_graph.nodes && url.data.summary_graph.nodes.length > 0) {
          const screenshot = url.data.summary_graph.nodes[0]['screenshot']
          cachResult[cach] = screenshot
          localStorage.setItem('cachedResults', JSON.stringify(cachResult))
          return { project_name : project['name'], screenshot : screenshot};
        }
      }
      cachResult[cach] = null
      localStorage.setItem('cachedResults', JSON.stringify(cachResult))

      return { project_name : project['name'], screenshot : null};
    });
        
        
      
        const screenshots = await Promise.all(promises);

        this.$nextTick(() => {
          

          screenshots.forEach((item) => {
            if(item.screenshot != null){
              const elementImage = document.querySelector(`.elt--banner_img[id='${item.project_name}']`);
              const elBannerOverlay = document.querySelector(`.elt--banner_overlay[id='banner_${item.project_name}']`);
              const span = elementImage.querySelector('span');
              elBannerOverlay.style.background = 'none';
              if(span != null){
                span.remove();
              }
              
              elementImage.style.backgroundImage = `url(data:image/png;base64,${item.screenshot})`;
              elementImage.style.backgroundSize = '100% 100%';
              elementImage.style.backgroundRepeat = 'no-repeat';
              elementImage.style.backgroundPosition = 'center';
            }else{
              console.log("No Image")
            }
          });
          
        });
      } catch (error) {
        console.error('An error occurred:', error);
        this.loadingProject = false;
      }
    },
    initializeProjectState() {
      this.filteredResultsProject = this.filteredResultProjectSet;
      this.handleSizeChangeProject(this.paginationProject.currentPage);
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    async getWringProject() {
      // if (!this.dataMe) {await this.getAuthMe()}

      const url = `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          const keys = Object.values(res.data.response);
          const projects = keys.map(item => {
            item.wring = true
            return item
          }) 
       
          this.resultProject = [...this.resultProject , ...projects];
          this.totalProject = res.data.response.length + keys.length;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error has occurred ",
        });
      }
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}x`;
      this.resultProject = []
      try {
        // axios.defaults.validateStatus = (status) => {
        //   // return status >= 200 && status < 401; // to catch 400 error
        //   return status === 400 || (status >= 200 && status < 500);
        // };
        // const res = await axios.get(url);
        // if ((res.data && res.data.status == "success") || res.data.status == 200) {
        //   this.resultProject = res.data.response;
        //   this.totalProject = res.data.response.length;
          
        // } else {
        //   this.resultProject = [];
        //   this.totalProject = 0;
        // }
        await this.getWringProject()
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error has occurred ",
        });
      } finally {
       
        if(this.totalProject == 0){
          this.noItems = true
        }
        await this.getUserShareProjet();
        this.loadingProject = false;
        this.filteredResultsProject = this.filteredResultProjectSet;
        this.initializeProjectState();
      }
    },
    async addProject(val) {
      this.loadingAddProject = true;
      if (val === "add") {
        let appUrls = this.$refs["data-rendered"].innerHTML
          .replace(/<span class="ml-1 u--tag">/g, "")
          .replace(/<span class="ml-1">/g, "")
          .replace(/<\/span>/g, ",")
          .split(",")
          .filter((s) => s.trim().length > 0)
          .map((s) => s.trim());
        if (this.ultraIpt) appUrls.push(this.ultraIpt);
        this.ultraIpt = "";
        let data = {
          // appUrls: appUrls,
          name: this.projectName,
        };
        console.log("data", data)
        if (appUrls) {
          data["appUrls"] = appUrls;
        }

        let tags = this.$refs["data-rendered-tags"].innerHTML
          .replace(/<span class="ml-1 u--tag">/g, "")
          .replace(/<span class="ml-1">/g, "")
          .replace(/<\/span>/g, ",")
          .split(",")
          .filter((s) => s.trim().length > 0)
          .map((s) => s.trim());
        if (this.ultraIpt_tags) tags.push(this.ultraIpt_tags);
        this.ultraIpt_tags = "";
        
        if (tags) {
          data["customjson"] = {
            tags:tags
          };
        }
        const url = this.createProjectMode == POSTHOG_MODE ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects` : `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1`;
        try {
          axios.defaults.validateStatus = (status) => {
            // return status >= 200 && status < 401; // to catch 400 error
            return status === 400 || (status >= 200 && status < 500);
          };
          const res = await axios.post(url, data);
          if ((res.data && res.data.status == "success") || res.data.status == 200) {

            if (!this.createProjectMode)
              this.detailProject(res.data.response.projectId);

            let message = this.projectName + " " + "was created successfully"
            this.$notify({
              type: "success",
              message: message,
            });
            this.addTestProject = false;
            this.cleanUpProject();
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "an error occurred while add the app.",
          });
        } finally {
          this.loadingAddProject = false;
          this.getProject();
          this.noItems = false
        }
      }
      if (val === "edit") {
        const url =  this.createProjectMode == POSTHOG_MODE  ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}` : `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1/${this.projectId}`;
        let data = {
          name: this.projectName,
        };
        let appUrls = "";
        if (this.ultraIpt.length > 1) appUrls = this.ultraIpt;
        if (appUrls.length > 1) {
          this.addUrls(appUrls);
        }
        let tags = this.$refs["data-rendered-tags"].innerHTML
          .replace(/<span class="ml-1 u--tag">/g, "")
          .replace(/<span class="ml-1">/g, "")
          .replace(/<\/span>/g, ",")
          .split(",")
          .filter((s) => s.trim().length > 0)
          .map((s) => s.trim());
        if (this.ultraIpt_tags) tags.push(this.ultraIpt_tags);
        this.ultraIpt_tags = "";
        if (tags) {
          data["customjson"] = this.projectDetail.customjson !== null ? this.projectDetail.customjson : {}
          data.customjson["tags"] = tags
        }

        try {
          axios.defaults.validateStatus = (status) => {
            // return status >= 200 && status < 401; // to catch 400 error
            return status === 400 || (status >= 200 && status < 500);
          };
          const res = await axios.put(url, data);
          if ((res.data && res.data.status == "success") || res.data.status == 200) {
            this.$notify({
              type: "success",
              message: `${this.projectName} was edited successfully`,
            });
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "an error occurred while edit the app.",
          });
        } finally {
          this.projectDetail = Object.assign({}, this.newProjectDetail);
          this.loadingAddProject = false;
          this.addTestProject = false;
          this.cleanUpProject();
          this.getProject();
          this.ultraIpt = "";
        }
      }
    },
    async editProjectModal(row) {
      this.ultraIpt = "";
      this.loadingAddProject = true;
      this.addTestProject = true;
      this.projectName = row.name;
      this.editProject = true;
      this.projectId = row.projectId;
      this.createProjectMode = "WRING_MODE"
      // this.createProjectMode = row.postHog ? "WRING_MODE" : "POSTHOG_MODE"

      this.$refs["data-rendered"].innerHTML = "";
      const id = row.projectId;
      const url = !row.wring ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${id}` : `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1/${id}` ;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status === "success") || res.data.status === 200) {
          
          res.data.response.appUrls.map((item) => {
            const elt = document.createElement("span");
            elt.classList.add("ml-1");
            elt.classList.add("u--tag");
            elt.innerHTML = " " + item + " ";
            this.$refs["data-rendered"].appendChild(elt);
          });

          if(res.data.response.customjson){
            this.$refs["data-rendered-tags"].innerHTML = ""
            res.data.response.customjson.tags.map((item) => {
            const elt = document.createElement("span");
            elt.classList.add("ml-1");
            elt.classList.add("u--tag");
            elt.innerHTML = " " + item + " ";
            this.$refs["data-rendered-tags"].appendChild(elt);
          });
          }else{
            this.$refs["data-rendered-tags"].innerHTML = ""
          }

          this.projectDetail = Object.assign({}, res.data.response);
          this.projectDetail.apiSnppet = `<script id="wring-script">!function(t,e){var o,n,p,r;e.__SV||(window.rinq=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src="${row.wring  ? 'https://wring.dev/assets/js/wring-capture.js' : 'https://wring.dev/assets/js/array.js' }",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="wring",u.people=u.people||[],u.toString=function(t){var e="wring";return"wring"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.rinq||[]);rinq.init('${this.projectDetail.apiKey}', {advanced_capture_all_elements: true, enable_recording_console_log: true, api_host:'${this.projectDetail.serverUrl}'})<\/script>
            <script id="apicall-snippet" src="https://storage.googleapis.com/wringapiutils/interceptor-packages/interceptor.min.js" onLoad="wringInterceptor.init('${this.projectDetail.apiKey}', '${process.env.VUE_APP_IS_DEMO == 0 ? 'dev' : process.env.VUE_APP_IS_DEMO == 1 ? 'prod' : 'alpha' }')"><\/script>`;
          this.projectDetail.jsSnppet = `<script> !function(t,e){var o,n,p,r;e.__SV||(window.rinq=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src="${row.wring ? 'https://wring.dev/assets/js/wring-capture.js' : 'https://wring.dev/assets/js/array.js' }",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="wring",u.people=u.people||[],u.toString=function(t){var e="wring";return"wring"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.rinq||[]);rinq.init('${this.projectDetail.apiKey}',{advanced_capture_all_elements: true, enable_recording_console_log: true, api_host:'${this.projectDetail.serverUrl}'}) <\/script> `;

          this.loadingAddProject = false;
        } else {
          this.loadingAddProject = false;
          this.addTestProject = false;
          this.editProject = false;
          this.$notify({
            type: "danger",
            duration: 5000,
            message: res.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while edit the app.",
        });
        console.error(error)
      }
    },
    async deleteProject(row) {
      console.log(row)
      const confirmation = await swal.fire({
        title: `Project "` + row.name + `"\nwill be removed`,
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "add_button",
        cancelButtonClass: "cancel_button",
        customClass: {
    
    confirmButton: 'add_button',
    cancelButton: 'add_button',
  },
      });
      const id = row.projectId;
      const url = !row.wring ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${id}`: `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1/${id}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 300);
        };

        if (confirmation.value === true) {
          const res = await axios.delete(url);
          if ((res.data && res.data.status == "success") || res.data.status == 200) {
            this.$notify({
              type: "success",
              message: `${row.name} was deleted successfully`,
            });
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while edit the app.",
        });
      } finally {
        this.getProject();
      }
    },
    async detailProject(row) {
      this.$refs["data-rendered-detail"].innerHTML = "";
      this.projectDetail = Object.assign({}, this.newProjectDetail);
      this.showDetailProject = true;
      this.loadingDetailProject = true;
      const id = row;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${id}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.projectDetail = Object.assign({}, res.data.response);
          this.projectDetail.appUrls.map((item) => {
            const elt = document.createElement("span");
            elt.classList.add("ml-1");
            elt.classList.add("u--tag");
            elt.innerHTML = " " + item + " ";
            this.$refs["data-rendered-detail"].appendChild(elt);
          });
          this.projectDetail.apiSnppet = `<script id="wring-script">!function(t,e){var o,n,p,r;e.__SV||(window.rinq=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src="${row.wring  ? 'https://wring.dev/assets/js/wring-capture.js' : 'https://wring.dev/assets/js/array.js' }",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="wring",u.people=u.people||[],u.toString=function(t){var e="wring";return"wring"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.rinq||[]);rinq.init('${this.projectDetail.apiKey}', {advanced_capture_all_elements: true, enable_recording_console_log: true, api_host:'${this.projectDetail.serverUrl}'})<\/script>
            <script id="apicall-snippet" src="https://storage.googleapis.com/wringapiutils/interceptor-packages/interceptor.min.js" onLoad="wringInterceptor.init('${this.projectDetail.apiKey}', '${process.env.VUE_APP_IS_DEMO == 0 ? 'dev' : process.env.VUE_APP_IS_DEMO == 1 ? 'prod' : 'alpha' }')"><\/script>`;
          this.projectDetail.jsSnppet = `<script> !function(t,e){var o,n,p,r;e.__SV||(window.rinq=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src="${row.wring  ? 'https://wring.dev/assets/js/wring-capture.js' : 'https://wring.dev/assets/js/array.js' }",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="wring",u.people=u.people||[],u.toString=function(t){var e="wring";return"wring"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.rinq||[]);rinq.init('${this.projectDetail.apiKey}', {advanced_capture_all_elements: true, enable_recording_console_log: true, api_host:'${this.projectDetail.serverUrl}'}) <\/script> `;
          this.loadingDetailProject = false;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: res.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while edit the app.",
        });
      }
    },
    async addUrls(urls) {
      const url = this.createProjectMode == POSTHOG_MODE ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}` : `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1`;
      let data = {
        appUrls: [
          {
            op: "add",
            url: urls,
          },
        ],
      };
      try {
        axios.defaults.validateStatus = (status) => {
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.put(url, data);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          return true;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "the addition of the new url has failed.",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteUrls(urls) {
      const url = this.createProjectMode == POSTHOG_MODE ? `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}` : `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1`;
      console.log(urls);
      let data = {
        appUrls: [
          {
            op: "remove",
            url: urls,
          },
        ],
      };
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.put(url, data);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          return true;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "deleting url failed .",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    showGuide() {
      const driverObj = driver({
          showProgress: true,
          steps: [
            { element: '.custom-pad', popover: { title: 'Search', description: "The search button for apps allows you to quickly locate and access specific applications within the platform." } },
            {
              element: '#addProjectButton', popover: {
                title: 'Add App', description: "To add a new app/project to your platform.", onNextClick: () => {
                  this.addTestProject = true
                  driverObj.moveNext();
                }
              }
            },
            { element: '.modal-content', popover: { title: 'Add New App', description: "Fill in the required details for the new app to be created." } },
            { element: '#project_name', popover: { title: 'App Name', description: "You're required to specify an app name for the creation of a new application." } },
            { element: '#projectUrl', popover: { title: 'App Url', description: "If you want to specify an app url for the creation of a new application." } },
            {
              element: '#updateDays', popover: { title: 'Update Trigger', description: 'Select the update trigger for the Appmap manually.' }, onDeselected: () => {
                this.addTestProject = false
              }
            },

            { element: '#listApps', popover: { title: 'List of Apps', description: "In this section, you'll find a comprehensive list of all available applications." } },
            { element: '#cardApp', popover: { title: 'App', description: 'This is a representation of how an application look like.' } },
            { element: '.custom--bell', popover: { title: 'Interactive Buttons', description: 'Here are the buttons to interact with the applications.' } },
          ]
        })
        driverObj.drive()
    },
    async delteSharedUser(user) {
      const confirmation = await swal.fire({
        title: "User '" + user + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (confirmation.value === true) {
        try {
           axios.defaults.validateStatus = (status) => {
            // return status >= 200 && status < 401; // to catch 400 error
            return status === 400 || (status >= 200 && status < 500);
          };
          const params = new URLSearchParams();
          params.append("project_systemid", this.sharedProjectId);
          let data = {
            users: [
              {
                op: "remove",
                email: user,
              },
            ],
          };
          const resp = await axios.put(`${process.env.VUE_APP_API_URL_PREFIX}/projects/v1/${this.sharedProjectId}`, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          if (resp.data.status === "success") {
            this.$notify({
              type: "success",
              duration: 5000,
              message: "User deleted successfully"
            })
            await this.getProject();
            await this.showAllSharedUsers(this.sharedProjectId, this.sharedProjectName)
            this.loadingSharedUsers = false
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: "User was not deleted"
            })
            this.loadingSharedUsers = false

          }
        } catch (error) {
          this.loadingSharedUsers = false
          console.log(error)
        }
      }
    },
    isEmailValid() {
      const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      return EMAIL_REGEXP.test(this.emailAddress);
    },
    async sendEmailAddress() {
      const input = document.getElementById("email_address")
      if (this.isEmailValid()) {
        input.style.borderColor = 'green';
        this.loadingSharedUsers = true
        try {
          axios.defaults.validateStatus = (status) => {
            // return status >= 200 && status < 401; // to catch 400 error
            return status === 400 || (status >= 200 && status < 500);
          };
          const params = new URLSearchParams();
          params.append("project_systemid", this.sharedProjectId);
          let data = {
            users: [
              {
                op: "add",
                email: this.emailAddress ,
                role: "member",
              },
            ],
          };
          const resp = await axios.put(`${process.env.VUE_APP_API_URL_PREFIX}/projects/v1/${this.sharedProjectId}`, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          if ((resp.data && resp.data.status === "success") || resp.data.status === 200) {
            this.$notify({
              type: "success",
              duration: 5000,
              message: `${this.sharedProjectName} shared successfully`
            })
            this.emailAddress = ""
            await this.getProject();
            this.showAllSharedUsers(this.sharedProjectId, this.sharedProjectName)
            this.loadingSharedUsers = false
          } else {
            this.loadingSharedUsers = false
            this.$notify({
              type: "danger",
              duration: 5000,
              message: resp.data.message
            })
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "User was not found"
          })
        }
        this.loadingSharedUsers = false
      } else {
        input.style.borderColor = 'red';
      }
    },
    async showAllSharedUsers(id, name) {
      const indexBob = this.resultProject.findIndex((el) => el.projectId === id);
      this.usersShared = this.resultProject[indexBob].shared
      this.showModalShareProject = true
      this.sharedProjectId = id
      this.sharedProjectName = name
    },
    async getUserShareProjet() {
      for (let i = 0; i < this.resultProject.length; i++) {
        if (this.resultProject[i].shared && this.resultProject[i].shared.length != 0) {
          const indexBob = this.resultProject.findIndex((el) => el.projectId === this.resultProject[i].projectId);
          this.resultProject[indexBob].shared = Object.values(this.resultProject[indexBob].shared)
        } else {
          const indexBob = this.resultProject.findIndex((el) => el.projectId === this.resultProject[i].projectId);
          this.resultProject[indexBob].shared = []
        }

      }

    },
  },
  computed: {
    filteredResultProjectSet() {
      const seq = this.resultProject;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
          if (this.queryProject.length > 0) {
            if (item.name.toLowerCase().includes(this.queryProject.toLowerCase())) {
              return item.name.toLowerCase().includes(this.queryProject.toLowerCase());
            }
          } else {
            return item;
          }
        })
        : [];
    },
    filteredListSession() {
      return this.filteredResultsSession.slice(this.from, this.to);
    },
    canAddPrevious() {
      if (this.pagination.currentPage == 1) return true;
      return false;
    },
  },
  watch: {
    queryProject: {
      handler: "initializeProjectState",
      immediate: true,
    },
    paginationProject: {
      handler: "initializeProjectState",
      immediate: false,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/custom/variablesCustom";
$pColor: #525f7f;
$color: #5e72e4;


.selection_settings{
  width: 100%;
  display: flex;
  align-items: center;
}
.grid_selection{
  width:100%;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2,1fr);
}
.grid_selection_custom{
  width:100%;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1,1fr);
}
.text-guide h3{
  font-size: 14px;
  font-weight: 500;
  color:var(--main-blue-color);
}
.text-guide{
  position: fixed;
  z-index: 1;
  display:inline;
  margin-top: 10px;
  right:125px;
}
.option_project_button{
  padding-right: 0;
  padding-left: 0;
  border: none;
  background-color: rgba($color: #000000, $alpha: 0);
}
.buttonAdd{
  z-index: 9;
  right: 2%;
  position: fixed;
  width: 180px;
  padding: 0.8em;
  text-align: center;
  border:1px solid #5603AD;
  border-radius: 5px;
  background-color: white; 
  color: black;
  font-size: 16px !important;
}
.fl--center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-test-project-modal {
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: flex-start;

  .modal-body {
    overflow-x: hidden;
    max-height: 60vh;
    overflow-y: auto;
  }
}

.run-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.model-label {
  color: var(--main-blue-color);
  display: block;
  font-size: 16px !important;
  font-weight: 500;
  position: relative;
  left: 0;
  margin-bottom: 4px;
}

.run-name input,
.run-name textarea,
.modal-checks-container textarea {
  background-color: var(--input-default-bg);
  color: var(--text-secondary);
  width: 100%;
  border-radius: 10px;
  padding: 7px;
  outline: none;
  border: 1px solid var(--input-default-bg) ;
  box-sizing: border-box;
  font-size: 13px;
}

.run-name input:focus,
.run-name textarea:focus,
.modal-checks-container textarea:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

.run-name input::-moz-placeholder,
.run-name textarea::-moz-placeholder {
  color: $color-plach-grey;
  font-size: 13px !important;
}

.run-name input::placeholder,
.run-name textarea::placeholder {
  color: $color-plach-grey;
  font-size: 13px !important;
}

.fbb {
  width: 100%;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 2px solid lightgray;
}

.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tic:nth-child(2) {
  padding: 0 5px;
}

.three-input input {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid $color-plach-grey;
  box-sizing: border-box;
  font-size: 13px;
}

.modal-checks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.modal-checks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 10px;
}

.modal-checks label {
  margin: 0 !important;
  padding-left: 7px;
  line-height: 1;
}

.modal-checks input {
  outline: none;
}

.mod-chk-last-input {
  width: 100px;
  margin: 0 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid $color-plach-grey;
  box-sizing: border-box;
  padding: 7px;
}

.mod-chk-last-input:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

[v-cloak] {
  display: none;
}

.upload-craglist label {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.craglist-files {
  width: 100%;
}

.craglist-files ul {
  border-top: 1px solid gray;
  padding: 0;
  margin: 0;
  width: 100%;
}

.craglist-files ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.rmv-btn {
  font-size: 20px;
  cursor: pointer;
}

.file-lists-container {
  width: 100%;
}

.file-head-clearall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.file-head-clearall .clearall {
  cursor: pointer;
}
.days-input{
  width:60px !important; 
  height:40px 1 !important;
  border-radius: 10px !important;
  text-align: center;
}
.upload-file-div-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #ddf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.el-table .el-table__header-wrapper thead tr th:nth-child(5) .cell {
  justify-content: center;
}

.down-btn-container a {
  color: #000000;
}

.down-btn-container a:hover {
  opacity: 0.6;
}

.down-btn-container a .fa-file-pdf,
.down-btn-container .fa-file-csv {
  font-weight: 600;
}

.progress-xpath-count {
  font-weight: bold;
  color: #4ac3db;
}

.onlyForRunningTestRow {
  background-color: #c4f1de !important;
}

.s-a-tabs {
  width: 100%;
  margin-top: 1em;
}



.test-run-name-for-suite {
  color: #2dce89;
  font-weight: bold;
}

.passed-status {
  font-weight: bold;
  font-size: 12px;
  color: $color-plach-grey;
  text-transform: capitalize;
}

.failed-status {
  background-color: $color-status-red;
  color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
}

.run-tags-cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.run-tags {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 3px;
  padding: 3px;
  font-size: 0.65rem;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
}

.test-time-cont {
  display: inline-block;
  max-width: 100% !important;
  font-size: 0.6rem;
  padding: 0.15rem 0.35rem;
  text-align: center;
  border: solid 0.1px rgba(#25a18e, 0.75);
  background: rgba(#25a18e, 0.15);
  margin-top: 5px;
  border-radius: 0.25rem;
  color: #25a18e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-add-test-project{
  margin-right: 22px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.looping-rhombuses-spinner,
.looping-rhombuses-spinner * {
  box-sizing: border-box;
}

.looping-rhombuses-spinner {
  width: 50%;
  height: 15px;
  position: relative;
  margin-left: 10px !important;
}

.script {
  background: var(--main-blue-color) !important;
  border-radius: 0.25rem;
}
.script p{
  color: #DBDBDB !important;
}

.pageview-modal .modal-content,
.pageview-modal .modal-dialog {
  width: auto;
  max-width: 80vw;
  max-height: 80vh;
}

.pageview-modal .modal-body {
  overflow: auto;
}

.add-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 600px;
  height: 10vh;
}

.show-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 900px;
}

.looping-rhombuses-spinner .rhombus {
  height: 15px;
  width: 15px;
  background-color: #4ac3db;
  left: calc(15px * 1);
  position: absolute;
  margin: 0 auto;
  border-radius: 2px;
  transform: translateY(0) rotate(45deg) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
}

.b {
  border: 1px solid red !important;
}

.for-row-selection .el-input {
  height: 100%;
  padding: 3px;
}

.test-descp {
  height: 10px;
  color: #9ea1a5;
  width: 50px;
}

.edit-test-select {
  width: 100%;
}

.edit-test-select .el-input {
  width: 100%;
}

.edit-test-modal-container .edit-test-select .el-select .el-input .el-input__inner {
  text-transform: capitalize !important;
}

.el-select-dropdown__item {
  text-transform: capitalize !important;
}

.add-test-project-modal button:disabled {
  cursor: not-allowed;
}

.for-pagination-circle-cursor .page-link {
  cursor: pointer;
}

.btn-gray {
  background-color: #172b4d !important;
}

.processing-screenshot-img {
  width: 150px;
  height: 100px;
  overflow: hidden;
}

.processing-screenshot-img img {
  /* border-radius: 10px; */
  /* border: 2px solid #fbb140; */
  width: 150px;
  height: 100px;
  object-fit: contain !important;
}

.add-test-footer-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.file-hover {
  background-color: #adfad9 !important;
}

.pakhpalle {
  background-color: red !important;
}

.looping-rhombuses-spinner .rhombus:nth-child(1) {
  animation-delay: calc(2500ms * 1 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(2) {
  animation-delay: calc(2500ms * 2 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(3) {
  animation-delay: calc(2500ms * 3 / -1.5);
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) rotate(45deg) scale(0);
  }

  50% {
    transform: translateX(233%) rotate(45deg) scale(1);
  }

  100% {
    transform: translateX(466%) rotate(45deg) scale(0);
  }
}

.app--animation {
  transition: 0.3s;
}



.narrow-select .el-input {
  height: 52px;
  width: 80px;
  padding: 3px;
}

.options-accordion .el-collapse-item__header {
  font-size: 16px;
}

// .custom--bell > div > .el-badge__content.is-fixed {
//   display: none !important;
// }


.small--select {
  max-width: 80px !important;
}

.c--breadcrumb {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 300;
  color: rgba($pColor, 0.6);
  // transform: translateY(-40px);
  width: calc(100% - 100px);

  .cb--divider {
    color: rgba($color, 0.6);
    font-size: 0.65rem;
  }

  &.to_move {
    transform: translate3d(30px, -36.5px, 0);
  }
}

.top--page,
.mid--page {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tp--l,
  .mp--l,
  .mp--r {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tp--title {
      font-size: 1.9rem;
      color: rgba($pColor, 0.85);
    }
  }
}




.mid--page {
  border-bottom: none !important;

  .el-input__inner {
    font-size: 0.75rem;
    padding: 0.35rem;
    background: #f7f9fc;

    &:focus {
      outline: none !important;
    }
  }

  .el-input__suffix {
    position: relative;
    margin-right: 80%;
    top: 10px;
    
  }
  
  .mp--l {
    span {
      color: rgba($pColor, 0.65);
      cursor: pointer;

      //font-size: 1.25rem;
      &.active {
        color: #25a18e;
      }
    }

    .small_btn {
      background: rgba($color, 0.1);
      border: solid 0.1px $color;

      i,
      span {
        font-size: 0.75rem !important;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }

      &.active {

        i,
        span {
          color: #fff !important;
        }
      }
    }

    .dashed--btn {
      border-style: dashed;
    }
  }

  .mp--r {
    .pag--details {
      color: rgba($pColor, 0.65);
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}
.add_project_section{
margin-bottom: 20px;
display: flex;
align-items: center;
gap: 30px;
}


.bpsd--table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  table-layout: fixed;

  .tbl--head {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    background: $color;
    color: #ffffff;
    overflow: hidden;

    th {
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;

      .th--sort {
        font-size: 0.6rem;
      }

      &:first-child {
        border-radius: 0.35rem 0 0 0.35rem;
      }

      &:last-child {
        border-radius: 0 0.35rem 0.35rem 0;
      }
    }
  }
}


.swal2-popup #swal2-styled #swal2-confirm{
  background-color: var(--main-blue-color);
border: 1px solid var(--main-blue-color);
border-radius: 30px;
color:#E6E6E6;
font-size: 18px;
width: 112px;
height: 44px;
line-height: normal;
}
modal{
  background-color: #EFEFEF;
}

@media (max-width: 850px) {
  .ctb--body {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}


@media (max-width: 630px) {
  .ctb--body {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .adpt-share--btn {
    font-size: 8px;
    padding: 5px;
  }
}

@media (max-width: 400px) {
  .ctb--body {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.ctb--body {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  flex-wrap: wrap;
  gap: 15px;

  .ctbb--row {
    width: 100% !important;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #ffffff;
    box-shadow: none;
    border-radius: 10px;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }
    .custom--bell{
      padding-top: 5px;
      margin: 4px 0 10px 10px;
      // background-color: #D6D6D6;
      // border-color:#D6D6D6 ;
    }
    .ctbb--inner {
      background-color: #D6D6D6;
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;

        .elt--banner_img {
          background-size : 100% 100%;
          background-repeat : no-repeat;
          background-position : center;
          display: inline-block;
          width: 100%;
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          color: var(--main-blue-color);
        }
        .elt--banner_name{
          background-color: #ffff;
        }
        .elt--banner_name .name{
          color:var(--main-blue-color);
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 200px;
          width: auto;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #fff;
          background: rgba($color, 0.85);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }

        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 10px;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          font-size: 0.8rem;
          color: rgba($pColor, 0.75);
          font-style: italic;
        }

        .elt--value {
          font-weight: 700;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}

.tbl--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 350px;

      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: #A8A8A8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;
        text-align: left;

        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;

          &.no--close {
            padding: 0.25rem 0.35rem;

            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #E0E0E0 ;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;

        img {
          height: 100%;
        }
      }

      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}

.modal-body {
  height: 60vh;
    overflow-y: scroll;
  padding-top: 40px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mp--filter_container {
  animation: slideDown 0.3s;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: auto;
    opacity: 1;
  }
}
.logo_section{
  display:flex;
  margin-bottom: 20px;
  gap:10px;
}

.sessionEvent-detail-modal {

  // width: 85vw !important;
  // margin: 1.75rem auto;
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100%;
      margin: 1.75rem auto;
    }
  }

  .modal-dialog {
    width: 85% !important;
    height: 10vh;
  }
}

.showCoveringtests-modal {

  // width: 85vw !important;
  // margin: 1.75rem auto;
  .modal-dialog {
    width: 80vw !important;
    height: 10vh;
  }
}

.swal2-popup #swal2-title {
  color: var(--main-blue-color) !important;
text-align: center;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
  display: block !important;
}
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.no-spinners {
    -moz-appearance: textfield;
}

.inp--project {
  width: 300px;
  height: 20px !important;
}

.ultra--ipt_content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 70%;

  .u--tag {
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.15rem 0.35rem;
    border: solid 0.1px rgba(#25a18e, 0.75);
    background: rgba(#25a18e, 0.15);
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .u--tag--i {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ultra--ipt_content,
.ultra--ipt_ipt {
  display: inline-block;
  height: 50px;
  outline: none;
  border: none;
  background: transparent;
}

.ultra--ipt_ipt {
  width: 100%;
}

.ultra--ipt {
  border: solid 0.1px #4b4b71;
  border-radius: 0.3rem;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  .ultra--ipt_content,
  .ultra--ipt_ipt {
    display: inline-block;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
  }

  .ultra--ipt_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 70%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_content--xphat {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 85%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_ipt {
    width: 100%;
  }

  .content--ultra {
    max-width: 75%;
  }

  .btn--clean {
    max-width: 22%;
  }
}

.el-input-number {
  line-height: 0px;
}

.snippet--inp {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin:0;
  width: 100%;
}

.list--downloadTestFile {
  border-bottom: solid 1px #f8f9fe;
  cursor: pointer;
}

.list--downloadTestFile:hover {
  color: #5f72e4;
  border-bottom: solid 1px #d5d5d5;
}

.ticks {
  z-index: 1;
  position: relative;
  width: 100%;
  bottom: 100%;
}

.ticks .tick-hover-box {
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: -15px;
  width: 5px;
  // right: -3px;
}

.ticks .tick-hover-box .tick-info {
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -36px;
  background-color: var(--bg-charcoal);
  display: none;
  color: var(--light);
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%);
}

.container-settings--appmap {
  gap:29px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ticks .tick-hover-box .tick-marker {
  position: absolute;
  width: 1px;
  height: 21px;
  background-color: #7e7e7e;
  right: 2px;
}

.ticks .tick-hover-box .tick-thumb {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  right: -2px;
  top: -4px;
  border: 0.5px solid #7e7e7e;
  background-color: #ffff;
  transform-origin: center;
  transition: transform 0.2s;
}
.container--video {
  display: flex;

  .video {
    width: 1080px;
  }

  .container--event--liste {
    margin: 0 0 0 30px;
    padding: 8px;
    width: 100%;
    border: solid 1px #c6c6c6;
    border-radius: 5px;
    max-height: 656px;
    overflow: hidden;
  }

  .event--liste {
    max-height: 580px;
    overflow-x: auto;
    overflow-y: none;
  }

  event--liste::-webkit-scrollbar {
    display: none;
  }

  .content {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;

    .time--tag {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-right: 1rem !important;
      width: 96%;
    }

    .right--content {
      width: 80%;
      flex-direction: column;
    }

    .icon--event {
      width: 20%;
      display: flex;
      background-color: #e5e5e5;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }

  .content:hover {
    background-color: #e8eeff;

    .icon--event {
      background-color: $color;

      i {
        color: #ffff;
      }
    }
  }

  .active--event {
    background-color: #e8eeff;

    .icon--event {
      background-color: $color;

      i {
        color: #ffff;
      }
    }
  }
}

.small_btn {
  background: rgba($color, 0.1);
  border: solid 0.1px $color;

  i,
  span {
    font-size: 0.75rem !important;
    font-weight: 600;
    color: $color;
  }

  &:hover {
    background: rgba($color, 0.3);

    i,
    span {
      color: rgba($color, 0.75);
    }
  }
}

.dashed--btn {
  border-style: dashed;
}

.elt--banner_name {
  height: 150px;
  font-size: 7em;
  text-transform: uppercase;
  font-weight: bold;
  color: #5f72e4;
}

.shared-btn {
  font-size: 0.7rem;
  font-weight: 700;
  color: #fff;
  background: rgba($color, 0.85);
  padding: 0.25rem;
  border-radius: 0.3rem;
}

.elt--banner_img {
  text-align: center;
}
#listApps{
  overflow-y:scroll;
  height: 65vh;
}
</style>

<style scoped lang="scss">
.el-badge__content {
  font-size: 10px;
  height: 19px;
}
@media(min-height: 800px){
  #listApps{
    height: 71vh !important;
  }
  .right-content{
    overflow-y: hidden !important;
  }
}
.content-project-card{
  z-index:999;
  position: absolute;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  bottom: -1px;
}
</style>
<style scoped>
.elt--content{
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
  background-color: rgba(1, 1, 1, 0) !important;
}
.elt--banner,.elt--banner_img{
  height: 100%;
}

.ctbb--row{
  min-height: 220px;
}
.project-name{
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


</style>
<style scoped>
.snippet--inp{
  margin-top: 0;
}
.custom-switch-round .el-switch.is-checked .el-switch__core::after{
  margin-left: -26px !important;
}
</style>