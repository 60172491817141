<template>
  <div class="right-content">
    <div class="header-steps-list">
      <div class="c--breadcrumb my-3">
        <router-link to="/details/test-explorer/list-tests"><i class="el-icon-house"></i></router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <router-link to="/details/project-explorer/list-projects">
          Apps
        </router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <router-link
          :to="`/details/project-explorer/view-project/${$route.params.id} ${$route.query.wring ? '?wring=true' : ''}`">
          {{ $route.params.page_name }}
        </router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <span> Step</span>

      </div>
      <div class="custom-header flex justify-between align-center mb-2" :loading="startLoading">
        <h3 class="page_name">Steps</h3>
        <div class="flex btns-gap justify-right">
          <div class="container--buttons">
            <!-- <el-tooltip content="Link To Session Video" placement="top">
              <button @click="routeToVideo()"
                class="ic--btn  adpt--btn test-buttons-triple custom--btn-dropdown video-arrow-icon"
                id="btnOpenVariables">
              </button>
            </el-tooltip> -->
            <!-- <button @click="toggleVariableModal()"
              class="ic--btn  adpt--btn test-buttons-triple custom--btn-dropdown brackets-icon" id="btnOpenVariables">
              <el-tooltip content="Variables" placement="top">
              </el-tooltip>
            </button> -->

            <el-button class="add_button " icon="upload-icon" size="small" v-if="!loadingTestFile" @click="showGenTest = true">
              Generate Test
            </el-button>
            <modal class="small-modal pt-modal" :show.sync="showGenTest">
              <div style="width:100%">
                <h5 class="edit-message-header">What Type Of Tests To Generate</h5>
              <div class="edit-message-body input-width-tall">
                <!-- <div v-if="loadingTestFile" class="el-input__inner loading-container">
                  <img src="/img/loading.gif" alt="loading..." style="width: 30%; height: 30%;" />
                </div>
                <el-input v-if="!loadingTestFile" class="input-width-tall" type="textarea" id="stepInputPrompt" name="stepInputPrompt"
                v-model="dowloadPrompt" :autosize="{ minRows: 8, maxRows: 30 }"
                placeholder="Give Some Hints To Guide Wringr AI To Create Tests"></el-input> -->
                <textarea
                  id="area-edit"
                  class="el-input__inner"
                  :placeholder="'Write a prompt'"
                  v-model="userPrompt"
                    ></textarea>
                  <div class="container-prompt" v-if="userPrompt === ''">
                    <button class="btn-option--prompt" @click="userPrompt = 'Generate an end to end test based on this session'">Generate an end to end test based on this session </button>
                    <button class="btn-option--prompt" @click="userPrompt = 'Generate a few tests based on this session'"> Generate a few tests based on this session</button>
                    <button class="btn-option--prompt"  @click="userPrompt = 'Generate different data combinations based on this session'"> Generate different data combinations based on this session</button>
                  </div>
                  <div v-if="loadingTestFile && !showProgressBar" class="el-input__inner loading-container">
                    <img src="/img/loading.gif" alt="loading..." style="height: 64px; width: 64px" />
                  </div>
                  <div   class="el-input__inner" v-if="loadingTestFile && showProgressBar" style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
                    <h2 style="margin: auto;"> Generating {{chosenDownloadFile.charAt(0).toUpperCase() + chosenDownloadFile.slice(1) }} Test</h2>
                    <el-progress class="prg-modal" type="dashboard"  :percentage="percentage"
                    :color="colors"></el-progress>
                  </div>
                  <div
                  v-else-if="!loadingTestFile && !edittingPrompt"
                  id="message-edit"
                  class="el-input__inner chat-box"

                  v-html="dowloadPrompt"
                  
                  @click="handleEditPrompt()"
                    >
                </div>
                <div style="position: relative; width: 100%;" v-if="edittingPrompt && !loadingTestFile">
                  <span class="close-gallery" id="close-gallery" 
                    style="position: absolute; cursor: pointer; top: -5px; right: 4%; font-size: 40px;" @click="closeEdittingPrompt()">&times;
                  </span>
                  <textarea
                    
                    id="message-edit"
                    class="el-input__inner"
                    v-model="showEditPrompt"
                      >
                  </textarea>
                  
                </div>
                
              </div>
              <div class="edit-message-footer">
                <div class="left-sbmt"> 
                  <button class="add_button" @click="downloadTestFile('preview')">Generate Preview</button>
                  <button class="add_button clear_btn-sp" v-if="userPrompt !== ''" @click="userPrompt = ''">Clear</button>
                  
                </div>
                <div class="right-btns"> 
                  <button class="add_button" style="margin-right: 10px;" v-if="edittingPrompt && !loadingTestFile" @click="modifyEdittingPrompt()">Modify</button>
                  <button v-if="dowloadPrompt != ''" class="add_button" @click="$refs.btnDownloadScripts.click()">Generate Test</button>
                  <button class="cancel_button" @click="showGenTest = false" style="margin-left: 10px;">Cancel</button>
                </div>
                <!-- <button v-else class="cancel_button" @click="showGenTest = false">
                  <img src="/img/loading.gif" alt="loading..." style="height: 22px; width: 22px" />
                </button> -->
              </div>
              </div>
            </modal>
            <div class="">
              <el-dropdown trigger="click"  style="display: none" :hide-on-click="false" type="primary" class="add_button"
                id="btnDownloadTests" >
                <el-tooltip :content="$t('view_item_page.download_scripts')" placement="top">
                  <button style="display: none" ref="btnDownloadScripts"  class="add_button" id="btnDownloadSccripts" >
                    Download Test
                  </button>
                </el-tooltip>

                <el-dropdown-menu slot="dropdown" class="cstm-dropdown--download">
                  <span class="close-gallery" id="close-gallery" 
                    style="position: absolute; cursor: pointer; top: -5px; right: 4%; font-size: 40px;" @click="$refs.btnDownloadScripts.click()">&times;
                  </span>
                  <h2 class="title-download">Choose Format</h2>
                  <el-dropdown-item class="custom--dropdown--item">
                    <el-radio v-model="chosenDownloadFile" label="wringr" class="ic--btn">
                      <el-tooltip content="Download wringr script file" placement="top">
                        <img src="../../../assets/images/favicon.png" alt="Observer script file" />
                      </el-tooltip>
                      <h2>Wringr</h2>

                    </el-radio>
                  </el-dropdown-item>
                  <el-dropdown-item class="custom--dropdown--item">
                    <el-radio v-model="chosenDownloadFile" label="playwright" class="ic--btn">
                      <el-tooltip content="Download playwright script file" placement="top">
                        <img src="../../../assets/images/playwright.svg" alt="Observer script file" />
                      </el-tooltip>
                      <h2>Playwright </h2>
                    </el-radio>
                  </el-dropdown-item>
                  
                  <el-dropdown-item class="custom--dropdown--item">
                    <el-radio v-model="chosenDownloadFile" label="cucumber" class="ic--btn">
                      <el-tooltip content="Download cucumber script file" placement="top">
                        <img src="../../../assets/images/Cucumber.svg" alt="Observer script file" />
                      </el-tooltip>
                      <h2>Cucumber</h2>
                    </el-radio>
                  </el-dropdown-item>
                  <el-dropdown-item class="custom--dropdown--item">
                    <el-radio v-model="chosenDownloadFile" label="cucumberconfig" class="ic--btn">
                      <el-tooltip content="Download cucumber config file" placement="top">
                        <img src="../../../assets/images/config.svg" alt="Observer script file" />
                      </el-tooltip>
                      <h2>Cucumber config</h2>
                    </el-radio>
                  </el-dropdown-item>
                  <el-dropdown-item class="custom--dropdown--item">
                    <el-radio v-model="chosenDownloadFile" label="sensetalk_zip" class="ic--btn">
                      <el-tooltip content="Download sensetalk suite file" placement="top">
                        <img src="../../../assets/images/SensetalkSuite.png" alt="Observer script file" />
                      </el-tooltip>
                      <h2>Sensetalk suite</h2>
                    </el-radio>
                  </el-dropdown-item>
                  <el-dropdown-item class="custom--dropdown--item">
                    <el-radio class="ic--btn" v-model="chosenDownloadFile" label="selenium">
                      <el-tooltip :content="$t(
            'view_item_page.download_selenium_script'
          )
            " placement="top">
                        <img src="../../../assets/images/selenium.png" alt="Selenium" />
                      </el-tooltip>
                      <h2>Selenium </h2>
                    </el-radio>
                  </el-dropdown-item>
                  <el-dropdown-item class="custom--dropdown--item">
                    <el-radio v-model="chosenDownloadFile" label="cypress" class="ic--btn">
                      <el-tooltip :content="$t(
            'view_item_page.download_generated_cypress'
          )
            " placement="top">
                        <img src="../../../assets/images/cypress.svg" alt="Katalon" />
                      </el-tooltip>
                      <h2>Cypress</h2>
                    </el-radio>
                  </el-dropdown-item>
                  <el-dropdown-item class="custom--dropdown--item">
                    <el-radio v-model="chosenDownloadFile" label="sensetalk" class="ic--btn">
                      <el-tooltip :content="$t(
            'view_item_page.download_generated_sensetalk'
          )
            " placement="top">
                        <img src="../../../assets/images/eggplant_logo.svg" alt="Sensetalk" />
                      </el-tooltip>
                      <h2>Sensetalk</h2>
                    </el-radio>
                  </el-dropdown-item>
                  <el-dropdown-item class="custom--dropdown--item">
                    <el-radio v-model="chosenDownloadFile" label="puppeteer" class="ic--btn">
                      <el-tooltip :content="$t(
            'view_item_page.download_generated_puppeteer'
          )
            " placement="top">
                        <img src="../../../assets/images/pptr.svg" alt="Puppeteer" />
                      </el-tooltip>
                      <h2>Puppeteer</h2>
                    </el-radio>
                  </el-dropdown-item>

                  <div class="btns-download">
                    <button class="add_button mt-4" type="button" @click.prevent="downloadTestFile(chosenDownloadFile) && $refs.btnDownloadScripts.click()">
                      Submit
                    </button>
                    <!-- <el-switch class="custom-switch-round" v-model="updateValidate" active-color="#1F213E"
                      active-text="Validate" inactive-color="#808080">
                    </el-switch> -->
                </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="container-download">
              <el-progress type="dashboard" v-if="loadingTestFile" :percentage="percentage"
                :color="colors"></el-progress>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="body-steps-list mt-4">
      <span v-if="startLoading && dataView === 'thumb'">
        <img src="/img/loading.gif" alt="loading..." height="100px" width="100px" />
      </span>
      <div v-else class="trs--container mt--2">
        <div style="border-bottom: none;" class="mid--page mb-4">
          <div class="mp--l" style="gap:10px">
            <span @click="saveDataView('thumb')" :class="{ active: dataView === 'thumb' }"><i
                class="ni-intersect"></i></span>
            <span @click="saveDataView('list')" :class="{ active: dataView === 'list' }"><i
                class="ni-column-horizontal"></i></span>
            <span @click="saveDataView('card')" :class="{ active: dataView === 'card' }"><i class="ni-grid"></i></span>
          </div>
          <div class="mp--r adpt--page-details">
            <span style="white-space:nowrap;margin:0 10px 0 10px">Steps : {{ total }}</span>
            <span>Show</span>
            <el-select v-model="perPage" @change="handleSizeChange" placeholder="Per page" class="small--select">
              <el-option v-for="(item, id) in pageSizes" :key="id" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
        </div>

        <div v-if="dataView === 'card'" class="bpsd_ctb" style="overflow-y:scroll;height: 60vh;">
          <span v-if="startLoading">
            <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
          </span>
          <div class="ctb--body mb-3" v-else>
            <template v-if="toShow.length > 0">
              <div v-for="(elt, id) in toShow" :key="elt.id" class="ctbb--row mb-2"
                :class="elt.status === 'processing' && 'ctbb--processing'">
                <div class="ctbb--inner custom-card-details">

                  <div class="elt--banner" v-if="elt.initialPageScreenshot">
                    <img v-if="elt.initialPageScreenshot" :src="`data:image/png;base64,${elt.initialPageScreenshot}`"
                      alt="screenshot of current step" class="elt--banner_img" />
                    <div class="elt--banner_overlay cursor--zoom" @click="
            previewPicture(
              `data:image/png;base64,${elt.initialPageScreenshot}`,
              elt
            )
            "></div>

                    <el-alert class="no--close elt--banner_overlay_status" show-icon
                      :title="elt.isActive ? 'Active' : 'Inactive'"
                      :type="elt.isActive ? 'success' : 'danger'"></el-alert>
                    <div class="step-fixture elt--banner_overlay_fixture"
                      v-if="elt.customjson !== null && elt.customjson.tags">
                      <el-tag class="elt-row--tag" type="success" size="mini">
                        {{ elt.customjson.tags }}
                      </el-tag>
                    </div>
                    <span v-if="elt.status === 'passed'" class="elt--banner_overlay_duration">
                      <i class="far fa-clock mr-1"></i>
                      {{ secondsToRealTime(elt) }}
                    </span>
                    <span class="elt--banner_overlay_created">
                      {{ formatDate(elt.sentAt) }}
                    </span>
                  </div>

                  <div class="elt--banner" v-else-if="elt.targetElement && elt.targetElement.step_pagescr">
                    <img v-if="elt.targetElement.step_pagescr"
                      :src="`data:image/png;base64,${elt.targetElement.step_pagescr}`" alt="screenshot of current step"
                      class="elt--banner_img" />
                    <div class="elt--banner_overlay cursor--zoom" @click="
            previewPictureWithoutSelector(
              `data:image/png;base64,${elt.targetElement.step_pagescr}`
            )
            "></div>
                    <el-alert class="no--close elt--banner_overlay_status" show-icon
                      :title="elt.isActive ? 'Active' : 'Inactive'"
                      :type="elt.isActive ? 'success' : 'danger'"></el-alert>
                    <div class="step-fixture elt--banner_overlay_fixture"
                      v-if="elt.customjson !== null && elt.customjson.tags">
                      <el-tag class="elt-row--tag" type="success" size="mini">
                        {{ elt.customjson.tags }}
                      </el-tag>
                    </div>
                    <span v-if="elt.status === 'passed'" class="elt--banner_overlay_duration">
                      <i class="far fa-clock mr-1"></i>
                      {{ secondsToRealTime(elt) }}
                    </span>
                    <span class="elt--banner_overlay_created">
                      {{ formatISODateTime(elt.createdAt) }}
                    </span>
                  </div>

                  <div class="elt--banner" :class="elt.initialPageScreenshot === null && 'no--img p-3'" v-else>
                    <img v-if="elt.initialPageScreenshot" src="/img/loading.gif" alt="loading..."
                      style="height: 100px; width: 100px" />
                    <small v-else>
                      <em><u>{{ $t("test_run_steps.no_screenshot") }}</u></em>
                    </small>
                  </div>

                  <div class="elt--content">

                    <div class="text-btns-container-card">
                      <span class="test-run-name">{{ $t("test_run_steps.step") }} {{ elt.$id + 1 }} </span><br />
                      <div>
                        <el-tooltip class="item" effect="light" :content="$t('test_run_steps.edit')" placement="top">
                          <el-button type="primary" icon="el-icon-edit"
                            @click.stop="handleAddEditStepModal(elt, id, true)" plain size="small"></el-button>
                        </el-tooltip>

                        <el-tooltip class="item" effect="light" :content="$t('test_run_steps.add')" placement="top">
                          <el-button type="success" icon="el-icon-plus" @click.stop="handleAddEditStepModal()" plain
                            size="small"></el-button>
                        </el-tooltip>

                        <el-tooltip class="item" effect="light" :content="$t('test_run_steps.delete')" placement="top">
                          <el-button type="danger" icon="el-icon-delete" @click.stop="deleteTestStep(elt)" plain
                            size="small">
                          </el-button>
                        </el-tooltip>
                      </div>
                    </div>


                    <span class="test-desc">
                      <span> {{ elt.stepDescription }}</span>
                    </span>
                    <div class="elt--footer" style="display:flex;gap:5px;" v-if="(isSharing === 'true' &&
            sharedJSON.scopes.includes('shared-edit')) ||
            isSharing === null
            ">
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="ctbb--row mb-2">No Steps Are Present</div>
            </template>
          </div>
        </div>

        <div v-if="dataView === 'list'">
          <span v-if="startLoading">
            <img src="/img/loading.gif" alt="loading..." height="100px" width="100px" />
          </span>
          <div v-else>
            <div class="text-center" v-if="toShow.length === 0">
              No Steps Are Present
            </div>
            <div v-else>
              <div class="trs--list trsl--steps">
                <el-card v-show="adding" class="trsl--elt" id="add-form" ref="add-test-step" shadow="hover">
                  <div class="add-accordion">
                    <div class="row mt-5">
                      <div class="col">
                        <div class="mod--content">
                          <el-form v-loading="stepLoading" @submit.native.prevent="addTestStepAction()">
                            <el-form-item :label="$t('test_run_steps.insert_new_step')" prop="existing step">
                              <el-select class="select-primary" style="display: none;"
                                :placeholder="$t('test_run_steps.new_step')" v-model="editedStep.targetStepId">
                                <el-option v-for="(item, index) in toShow" class="select-primary" :value="item.stepId"
                                  :label="(index + from + 1) + ' - ' + item.stepDescription" :key="item.stepNumber">
                                </el-option>
                              </el-select>
                            </el-form-item>
                            <el-form-item style="max-width: 220px;" :label="$t('test_run_steps.action_to_perform')"
                              prop="Action">
                              <el-select class="select-danger" placeholder="Actions" v-model="editedStep.action">
                                <el-option v-for="option in stepActionMap" class="select-danger" :value="option.value"
                                  :label="option.label" :key="option.value">
                                </el-option>
                              </el-select>
                            </el-form-item>
                            <el-row :gutter="20">
                              <el-col :span="6">
                                <el-form-item style="max-width: 220px;" :label="$t('test_run_steps.selector_type')"
                                  prop="prop3">
                                  <el-select class="select-danger" :placeholder="$t('test_run_steps.choose_selector_type')
            " v-model="editedStep.selectorType">
                                    <el-option v-for="option in stepSelectorList" class="select-danger"
                                      :value="option.value" :label="option.label" :key="option.label">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="18">
                                <el-form-item style="max-width: 220px;" :label="$t('test_run_steps.selector_to_use')">
                                  <el-input style="height:90px" type="text" id="stepSelector" name="stepSelector"
                                    v-model="editedStep.selector" :placeholder="$t('test_run_steps.enter_selector')
            "></el-input>
                                </el-form-item>
                              </el-col>
                            </el-row>

                            <el-form-item
                              :label="editedStep.action.includes('click') ? $t('test_run_steps.other_items') : $t('test_run_steps.input_values')">
                              <el-input type="text" id="stepInputValue" name="stepInputValue"
                                v-model="editedStep.inputValue"
                                :placeholder="$t('test_run_steps.enter_value')"></el-input>
                            </el-form-item>
                            <div class="edit-accordion-btns" style="display:flex;gap:5px">
                              <div>
                                <button class="cancel_button" type="button" @click="adding = false">
                                  {{ $t("test_run_steps.cancel") }}
                                </button>
                              </div>
                              <div>
                                <button class="add_button" native-type="submit">
                                  {{ $t("test_run_steps.save") }}
                                </button>
                              </div>
                            </div>
                          </el-form>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col"></div>
                    </div>
                  </div>
                </el-card>
                <draggable :list="toShow" :disabled="!enabled" class="list-group" ghost-class="ghost"
                  @start="dragging = true" @end="dragging = false" :move="updateReorder" handle=".drag-only-row">
                  <transition-group class="trsl--parent custom-trsl--parent">
                    <el-card v-for="(row, index) in toShow" :key="row.$id" class="trsl--elt" shadow="hover">
                      <el-row class=" drag-only-row">
                        <div style="display: grid;grid-template-columns: 100px 70% 1fr;">
                          <el-col :span="2" class="trsl--txt">
                            <p>
                              {{ row.$id + from + 1 }}
                            </p>
                          </el-col>
                          <el-col :span="18" class="trsl--txt">
                            <div class="step-description">
                              <h2 style="font-size:0.85rem; font-weight: 500;"> {{ row.stepDescription }}</h2>
                              <!-- <div v-if="selectedItemId === index" class="edit-desc"> 

                              <el-input class="edt-desc" type="textarea" style="border-radius: 10px !important;"
                              :autosize="{ minRows: 3, maxRows: 6}" v-model="editedStep.stepDescription" ></el-input>
                              </div>  -->
                            </div>
                            <div class="step-fixture" v-if="row.customjson !== null && row.customjson.tags">
                              <el-tag class="elt-row--tag" type="success" size="mini">
                                {{ row.customjson.tags }}
                              </el-tag>
                            </div>
                          </el-col>
                          <div class="el-col flex btns-gap justify-center" v-if="(isSharing === 'true' &&
            sharedJSON.scopes.includes('shared-edit')) ||
            isSharing === null
            ">
                            <el-tooltip :content="$t('test_run_steps.edit')" placement="top">
                              <el-button class="options-btn-step" type="primary" icon="el-icon-edit"
                                @click.stop="openEditAccordion(row, index, true)" circle plain size="small"></el-button>
                            </el-tooltip>

                            <el-tooltip :content="$t('test_run_steps.add')" placement="top">
                              <el-button class="options-btn-step" type="success" icon="el-icon-plus"
                                @click.stop="openEditAccordion(row, index, false)" circle plain
                                size="small"></el-button>
                            </el-tooltip>

                            <el-tooltip :content="$t('test_run_steps.delete')" placement="top">
                              <el-button class="options-btn-step" type="danger" icon="el-icon-delete"
                                @click.stop="deleteTestStep(row)" circle plain size="small"></el-button>
                            </el-tooltip>
                          </div>
                        </div>
                      </el-row>


                      <!-- Edit Step Start -->
                      <div class="edit-accordion" v-show="index === selectedItemId">
                        <div class="small-modal left-footer">
                          <h2 class="accordion-title">{{ modalTitle }}</h2>
                          <div style="width: 100%">
                            <!-- <button class="add_button" @click="handleShowScreen()"
                              v-if="editedStep.initialPageScreenshot !== ''">
                              {{ modalScreenshot }}
                            </button>
                            <div class="container-modal-screen">
                              <img v-if="editedStep.initialPageScreenshot && isShowSceenshot"
                                :src="`data:image/png;base64,${editedStep.initialPageScreenshot}`"
                                alt="screenshot of current step" class="elt--modal_img"
                                @click="previewPictureWithoutSelector(`data:image/png;base64,${editedStep.initialPageScreenshot}`)" />
                            </div> -->
                            <!-- <div v-else-if="!editedStep.initialPageScreenshot && !isShowSceenshot"></div> -->
                            <div>
                              <el-form v-loading="stepLoading" @submit.native.prevent="addTestStepAction()">
                                <el-form-item class="cstm-form--insert" v-if="editedIndex === -1" prop="existing step">
                                  <label class="el-insert-item__label">Insert new step</label>
                                  <el-select class="select-primary cstm-insert" :placeholder="editedStep.insertItem"
                                    v-model="editedStep.insertItem" @change="handleInsertItem()">
                                    <el-option v-for="(item, index) in optionsInsert" class="select-primary"
                                      :value="item" :label="item" :key="index">
                                    </el-option>
                                  </el-select>
                                  <label class="el-insert-item__label"> existing step</label>
                                  <el-select class="select-primary" style="display: none;"
                                    :placeholder="$t('test_run_steps.new_step')" v-model="editedStep.targetStepId"
                                    ref="selectStep" @change="changeStepId()">
                                    <el-option v-for="(item, index) in toShow" class="select-primary" ref="stepname"
                                      :value="item.stepId" :label="(index + from + 1) + ' - ' + item.stepDescription"
                                      :key="item.stepNumber">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                                <div
                                  :class="editedStep.action === 'Put' || editedStep.action === 'Post' || editedStep.action === 'Get' || editedStep.action === 'Delete' || editedStep.action === 'playwright' || editedStep.action === 'command' ? 'edit-short-modal-grid' : 'edit-body-modal-grid'">
                                  <el-form-item :label="$t('test_run_steps.action_to_perform')" prop="Action">
                                    <el-select class="input-width-tall" popper-class="custom-dropdown-popup-steps"
                                      placeholder="Actions" v-model="editedStep.action" @change="handleChangeAction()">
                                      <!-- <el-option v-for="option in stepActionMap" :value="option.value" :label="option.label"
                          :key="option.value"> -->
                                      <el-option-group v-for="group in stepActionMap" :key="group.label"
                                        :label="group.label">
                                        <el-option v-for="item in group.options[0]" :key="item.value"
                                          :label="item.label" :value="item.value">
                                          <!-- {{item.value}} -->
                                        </el-option>
                                      </el-option-group>
                                    </el-select>
                                  </el-form-item>
                                  <el-col :span="6">
                                    <el-form-item
                                      v-if="editedStep.action.includes('click') || editedStep.action.includes('send_keys')"
                                      :label="$t('test_run_steps.other_items')">
                                      <el-input class="input-width-tall" type="text" id="stepInputValue"
                                        name="stepInputValue" v-model="editedStep.inputValue"
                                        :placeholder="$t('test_run_steps.enter_value')"></el-input>
                                    </el-form-item>
                                    <el-form-item :label="$t('test_run_steps.input_values')"
                                      v-if="editedStep.action !== 'Put' && editedStep.action !== 'Post' && editedStep.action !== 'Get' && editedStep.action !== 'Delete' && editedStep.action !== 'playwright' && editedStep.action !== 'command' && !editedStep.action.includes('click') && !editedStep.action.includes('send_keys')">
                                      <el-input class="input-width-tall" type="text" id="stepInputValue"
                                        name="stepInputValue" v-model="editedStep.inputValue"
                                        :placeholder="$t('test_run_steps.enter_value')"></el-input>
                                    </el-form-item>
                                  </el-col>
                                  <div
                                    v-if="editedStep.action === 'Put' || editedStep.action === 'Post' || editedStep.action === 'Get' || editedStep.action === 'Delete' || editedStep.action === 'playwright' || editedStep.action === 'command'">
                                    <el-tooltip
                                      content="Get Request| Post URL| Put headers| Post body| Assert success or failure">
                                      <el-form-item label="Command">
                                        <el-input class="input-width-tall" type="textarea" id="stepInputCommand"
                                          name="stepInputCommand" v-model="editedStep.command"
                                          :autosize="{ minRows: 6, maxRows: 30 }"
                                          placeholder="Get Request| Post URL| Put headers| Post body| Assert success or failure"></el-input>
                                      </el-form-item>
                                    </el-tooltip>

                                  </div>
                                </div>
                                <div class="container-expand">
                                  <el-collapse>
                                    <el-collapse-item title="Advanced Settings">
                                      <el-col :span="18"
                                        v-if="editedStep.action !== 'Put' && editedStep.action !== 'Post' && editedStep.action !== 'Get' && editedStep.action !== 'Delete' && editedStep.action !== 'playwright' && editedStep.action !== 'command'">
                                        <el-form-item :label="$t('test_run_steps.selector_to_use')">
                                          <el-input class="input-width-tall" type="text" id="stepSelector"
                                            name="stepSelector" v-model="editedStep.selector"
                                            :placeholder="$t('test_run_steps.enter_selector')"></el-input>
                                        </el-form-item>
                                      </el-col>




                                      <el-form-item :label="$t('test_run_steps.selector_type')" prop="prop3">
                                        <el-select class="select-danger"
                                          :placeholder="$t('test_run_steps.choose_selector_type')"
                                          v-model="editedStep.selectorType">
                                          <el-option v-for="option in stepSelectorList" class="select-danger"
                                            :value="option.value" :label="option.label" :key="option.label">
                                          </el-option>
                                        </el-select>
                                      </el-form-item>
                                    </el-collapse-item>
                                  </el-collapse>
                                </div>
                                <el-form-item class="enable-switch-cont" prop="prop6">
                                  <div>
                                    <h3 class="text-title">Enable Step</h3>
                                  </div>
                                  <el-tooltip content="Enable Step">
                                    <el-switch v-model="toggleStepValue" active-color="#1F213E" inactive-color="#759DCB"
                                      @change="toggleStep(row)"></el-switch>
                                  </el-tooltip>
                                  <div style="margin-left:10px">
                                    <h3 class="text-title"> Fixture </h3>
                                  </div>
                                  <el-tooltip
                                    :content="editedStep.action === '$pageview' ? 'Not Allowed For Pagview Actions' : 'Enable Tag Fixture'">
                                    <el-switch :disabled="editedStep.action === '$pageview' ? true : false"
                                      v-model="editedStep.fixture" active-color="#1F213E"
                                      inactive-color="#759DCB"></el-switch>
                                  </el-tooltip>
                                  <el-form-item style="margin-left: 10px"
                                    v-if="editedStep.fixture && listFixtureTags !== null && listFixtureTags.length > 0 && listFixtureTags[0]?.options !== null">
                                    <!-- <el-input class="input-width-tall" id="stepInputFixture"
                                          name="stepInputFixture" v-model.lazy="editedStep.fixtureTag"
                                          @change="toggleFixture(row)"
                                          placeholder="Enter Fixture"></el-input> -->
                                    <el-select class="select-danger" placeholder="Choose Fixture"
                                      v-model="editedStep.fixtureTag" @change="toggleFixture(row)">
                                      <el-option v-for="(option, id) in listFixtureTags[0].options" class="select-danger"
                                        :value="option.value" :label="option.label" :key="id">
                                      </el-option>
                                    </el-select>
                                  </el-form-item>
                                  <label
                                    v-else-if="editedStep.fixture && (listFixtureTags[0]?.options === null || listFixtureTags !== null || listFixtureTags.length > 0)">
                                    Please Write Fixture On App Page </label>
                                  <!-- <div style="margin-left:10px" v-if="editedStep.action === 'command'"> 
                                      <el-tooltip content="Enable Mock">
                                        <h3 class="text-title" style="margin-right: 10px;"> Mock </h3>
                                        <el-switch v-model="editedStep.isMockResponse" active-color="#1F213E"
                                          inactive-color="#759DCB"></el-switch>
                                      </el-tooltip>
                                    </div> -->

                                </el-form-item>
                                <el-input v-if="editedStep.isMockResponse" class="input-width-tall" type="textarea"
                                  id="stepInputCommand" name="stepInputCommand" v-model="editedStep.APIMockResponse"
                                  placeholder='Enter expected output' style="margin-bottom: 10px;"></el-input>
                                </el-form-item>
                                <div class="edit-accordion-btns">
                                  <button class="add_button" native-type="submit">
                                    {{ modalActionText }}
                                  </button>
                                  <button class="cancel_button" type="button"
                                    @click="selectedItemId = null">Cancel</button>
                                </div>

                              </el-form>
                            </div>
                          </div>


                        </div>
                      </div>
                      <!-- Edit Step End -->
                    </el-card>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <div v-if="dataView === 'thumb'">

          <div v-if="!startLoading">
            <div class="text-center" v-if="toShow.length === 0">
              No Steps Are Present
            </div>
            <div v-else>
              <div class="trs--list trsl--steps">
                <el-card v-show="adding" class="trsl--elt" id="add-form" ref="add-test-step" shadow="hover">
                  <div class="add-accordion">
                    <div class="row mt-5">
                      <div class="col">
                        <div class="mod--content">
                          <el-form v-loading="stepLoading" @submit.native.prevent="addTestStepAction()">
                            <el-form-item :label="$t('test_run_steps.insert_new_step')" prop="existing step">
                              <el-select class="select-primary" style="display: none;"
                                :placeholder="$t('test_run_steps.new_step')" v-model="editedStep.targetStepId">
                                <el-option v-for="(item, index) in toShow" class="select-primary"
                                  :value="item.stepNumber" :label="(index + from + 1) + ' - ' + item.stepDescription"
                                  :key="item.stepNumber">
                                </el-option>
                              </el-select>
                            </el-form-item>
                            <el-form-item style="max-width: 220px;" :label="$t('test_run_steps.action_to_perform')"
                              prop="Action">
                              <el-select class="select-danger" placeholder="Actions" v-model="editedStep.action">
                                <el-option v-for="option in stepActionMap" class="select-danger" :value="option.value"
                                  :label="option.label" :key="option.value">
                                </el-option>
                              </el-select>
                            </el-form-item>
                            <el-row :gutter="20">
                              <el-col :span="6">
                                <el-form-item style="max-width: 220px;" :label="$t('test_run_steps.selector_type')"
                                  prop="prop3">
                                  <el-select class="select-danger" :placeholder="$t('test_run_steps.choose_selector_type')
            " v-model="editedStep.selectorType">
                                    <el-option v-for="option in stepSelectorList" class="select-danger"
                                      :value="option.value" :label="option.label" :key="option.label">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="18">
                                <el-form-item style="max-height:50px" :label="$t('test_run_steps.selector_to_use')">
                                  <el-input style="height:90px" type="text" id="stepSelector" name="stepSelector"
                                    v-model="editedStep.selector" :placeholder="$t('test_run_steps.enter_selector')
            "></el-input>
                                </el-form-item>
                              </el-col>
                            </el-row>

                            <el-form-item :label="$t('test_run_steps.input_value')">
                              <el-input type="text" id="stepInputValue" name="stepInputValue"
                                v-model="editedStep.inputValue"
                                :placeholder="$t('test_run_steps.enter_value')"></el-input>
                            </el-form-item>
                            <div class="edit-accordion-btns" style="display:flex;gap:5px">
                              <div>
                                <button class="cancel_button" type="button" @click="selectedItemId = null">
                                  {{ $t("test_run_steps.cancel") }}
                                </button>
                              </div>
                              <div>
                                <button class="add_button" native-type="submit">
                                  {{ $t("test_run_steps.save") }}
                                </button>
                              </div>
                            </div>
                          </el-form>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-card>
                <draggable :list="toShow" :disabled="!enabled" class="list-group" ghost-class="ghost"
                  @start="dragging = true" @end="dragging = false" :move="updateReorder" handle=".drag-only-row">
                  <transition-group class="trsl--parent custom-trsl--parent" style="overflow-y:scroll;height: 41vh;">
                    <el-card v-for="(row, index) in toShow" :key="row.$id" class="trsl--elt" shadow="hover">
                      <el-row type="flex" justify="space-between" class="el--flex drag-only-row" :gutter="20">
                        <el-col :span="3" class="trsl--img">
                          <img v-if="row.initialPageScreenshot" @click="
            previewPicture(
              `data:image/png;base64,${row.initialPageScreenshot}`,
              row
            )
            " :src="`data:image/png;base64,${row.initialPageScreenshot}`" />
                          <img v-else-if="row.targetElement && row.targetElement.step_pagescr" @click="
            previewPictureWithoutSelector(
              `data:image/png;base64,${row.targetElement.step_pagescr}`
            )
            " :src="`data:image/png;base64,${row.targetElement.step_pagescr}`" />
                          <img v-else src="/img/not-images.png" />
                        </el-col>
                        <el-col :span="1" class="trsl--txt">
                          <p>
                            {{ row.$id + from + 1 }}
                          </p>
                        </el-col>
                        <el-col :span="15" class="trsl--txt">
                          <div class="step-description">
                            <h2 style="font-size: 0.85rem; font-weight: 500;"> {{ row.stepDescription }}</h2>
                            <!-- <div v-if="selectedItemId === index" class="edit-desc">  -->

                            <!-- <el-input class="edt-desc" type="textarea" style="border-radius: 10px !important;"
                              :autosize="{ minRows: 3, maxRows: 6}" v-model="editedStep.stepDescription" ></el-input>
                              </div>  -->
                          </div>
                          <div class="step-fixture" v-if="row.customjson !== null && row.customjson.tags">
                            <el-tag class="elt-row--tag" type="success" size="mini">
                              {{ row.customjson.tags }}
                            </el-tag>
                          </div>
                        </el-col>
                        <div class="el-col" style="display: flex;gap:5px" v-if="(isSharing === 'true' &&
            sharedJSON.scopes.includes('shared-edit')) ||
            isSharing === null
            ">
                          <el-tooltip :content="$t('test_run_steps.edit')" placement="top">
                            <el-button type="primary" icon="el-icon-edit"
                              @click.stop="openEditAccordion(row, index, true)" circle plain size="small"></el-button>
                          </el-tooltip>

                          <el-tooltip :content="$t('test_run_steps.add')" placement="top">
                            <el-button type="success" icon="el-icon-plus"
                              @click.stop="openEditAccordion(row, index, false)" circle plain size="small"></el-button>
                          </el-tooltip>

                          <el-tooltip :content="$t('test_run_steps.delete')" placement="top">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="deleteTestStep(row)" circle
                              plain size="small"></el-button>
                          </el-tooltip>
                        </div>
                      </el-row>

                      <!-- Edit Step Start -->
                      <div class="edit-accordion" v-show="index === selectedItemId">
                        <div class="small-modal left-footer">
                          <h2 class="accordion-title">{{ modalTitle }}</h2>
                          <div style="width: 100%">
                            <!-- <button class="add_button" @click="handleShowScreen()"
                              v-if="editedStep.initialPageScreenshot !== ''">
                              {{ modalScreenshot }}
                            </button>
                            <div class="container-modal-screen">
                              <img v-if="editedStep.initialPageScreenshot && isShowSceenshot"
                                :src="`data:image/png;base64,${editedStep.initialPageScreenshot}`"
                                alt="screenshot of current step" class="elt--modal_img"
                                @click="previewPictureWithoutSelector(`data:image/png;base64,${editedStep.initialPageScreenshot}`)" />
                            </div> -->
                            <!-- <div v-else-if="!editedStep.initialPageScreenshot && !isShowSceenshot"></div> -->
                            <div>
                              <el-form v-loading="stepLoading" @submit.native.prevent="addTestStepAction()">
                                <el-form-item class="cstm-form--insert" v-if="editedIndex === -1" prop="existing step">
                                  <label class="el-insert-item__label">Insert new step</label>
                                  <el-select class="select-primary cstm-insert" :placeholder="editedStep.insertItem"
                                    v-model="editedStep.insertItem" @change="handleInsertItem()">
                                    <el-option v-for="(item, index) in optionsInsert" class="select-primary"
                                      :value="item" :label="item" :key="index">
                                    </el-option>
                                  </el-select>
                                  <label class="el-insert-item__label"> existing step</label>
                                  <el-select class="select-primary" style="display: none;"
                                    :placeholder="$t('test_run_steps.new_step')" v-model="editedStep.targetStepId"
                                    ref="selectStep" @change="changeStepId()">
                                    <el-option v-for="(item, index) in toShow" class="select-primary" ref="stepname"
                                      :value="item.stepId" :label="(index + from + 1) + ' - ' + item.stepDescription"
                                      :key="item.stepNumber">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                                <div
                                  :class="editedStep.action === 'Put' || editedStep.action === 'Post' || editedStep.action === 'Get' || editedStep.action === 'Delete' || editedStep.action === 'playwright' || editedStep.action === 'command' ? 'edit-short-modal-grid' : 'edit-body-modal-grid'">
                                  <el-form-item :label="$t('test_run_steps.action_to_perform')" prop="Action">
                                    <el-select class="input-width-tall" popper-class="custom-dropdown-popup-steps"
                                      placeholder="Actions" v-model="editedStep.action" @change="handleChangeAction()">
                                      <!-- <el-option v-for="option in stepActionMap" :value="option.value" :label="option.label"
                          :key="option.value"> -->
                                      <el-option-group v-for="group in stepActionMap" :key="group.label"
                                        :label="group.label">
                                        <el-option v-for="item in group.options[0]" :key="item.value"
                                          :label="item.label" :value="item.value">
                                          <!-- {{item.value}} -->
                                        </el-option>
                                      </el-option-group>
                                    </el-select>
                                  </el-form-item>
                                  <el-col :span="6">
                                    <el-form-item
                                      v-if="editedStep.action.includes('click') || editedStep.action.includes('send_keys')"
                                      :label="$t('test_run_steps.other_items')">
                                      <el-input class="input-width-tall" type="text" id="stepInputValue"
                                        name="stepInputValue" v-model="editedStep.inputValue"
                                        :placeholder="$t('test_run_steps.enter_value')"></el-input>
                                    </el-form-item>
                                    <el-form-item :label="$t('test_run_steps.input_values')"
                                      v-if="editedStep.action !== 'Put' && editedStep.action !== 'Post' && editedStep.action !== 'Get' && editedStep.action !== 'Delete' && editedStep.action !== 'playwright' && editedStep.action !== 'command' && !editedStep.action.includes('click') && !editedStep.action.includes('send_keys')">
                                      <el-input class="input-width-tall" type="text" id="stepInputValue"
                                        name="stepInputValue" v-model="editedStep.inputValue"
                                        :placeholder="$t('test_run_steps.enter_value')"></el-input>
                                    </el-form-item>
                                  </el-col>
                                  <div
                                    v-if="editedStep.action === 'Put' || editedStep.action === 'Post' || editedStep.action === 'Get' || editedStep.action === 'Delete' || editedStep.action === 'playwright' || editedStep.action === 'command'">
                                    <el-tooltip
                                      content="Get Request| Post URL| Put headers| Post body| Assert success or failure">
                                      <el-form-item label="Command">
                                        <el-input class="input-width-tall" type="textarea" id="stepInputCommand"
                                          name="stepInputCommand" v-model="editedStep.command"
                                          :autosize="{ minRows: 6, maxRows: 30 }"
                                          placeholder="Get Request| Post URL| Put headers| Post body| Assert success or failure"></el-input>
                                      </el-form-item>
                                    </el-tooltip>
                                  </div>
                                </div>
                                <div class="container-expand">
                                  <el-collapse>
                                    <el-collapse-item title="Advanced Settings">
                                      <el-col :span="18"
                                        v-if="editedStep.action !== 'Put' && editedStep.action !== 'Post' && editedStep.action !== 'Get' && editedStep.action !== 'Delete' && editedStep.action !== 'playwright' && editedStep.action !== 'command'">
                                        <el-form-item :label="$t('test_run_steps.selector_to_use')">
                                          <el-input class="input-width-tall" type="text" id="stepSelector"
                                            name="stepSelector" v-model="editedStep.selector"
                                            :placeholder="$t('test_run_steps.enter_selector')"></el-input>
                                        </el-form-item>
                                      </el-col>




                                      <el-form-item :label="$t('test_run_steps.selector_type')" prop="prop3">
                                        <el-select class="select-danger"
                                          :placeholder="$t('test_run_steps.choose_selector_type')"
                                          v-model="editedStep.selectorType">
                                          <el-option v-for="option in stepSelectorList" class="select-danger"
                                            :value="option.value" :label="option.label" :key="option.label">
                                          </el-option>
                                        </el-select>
                                      </el-form-item>
                                    </el-collapse-item>
                                  </el-collapse>
                                </div>
                                <el-form-item class="enable-switch-cont" prop="prop6">
                                  <div>
                                    <h3 class="text-title">Enable Step</h3>
                                  </div>
                                  <el-tooltip content="Enable Step">
                                    <el-switch v-model="toggleStepValue" active-color="#1F213E" inactive-color="#759DCB"
                                      @change="toggleStep(row)"></el-switch>
                                  </el-tooltip>
                                  <div style="margin-left:10px">
                                    <h3 class="text-title"> Fixture </h3>
                                  </div>
                                  <el-tooltip
                                    :content="editedStep.action === '$pageview' ? 'Not Allowed For Pagview Actions' : 'Enable Tag Fixture'">
                                    <el-switch :disabled="editedStep.action === '$pageview' ? true : false"
                                      v-model="editedStep.fixture" active-color="#1F213E"
                                      inactive-color="#759DCB"></el-switch>
                                  </el-tooltip>
                                  <el-form-item style="margin-left: 10px"
                                    v-if="editedStep.fixture && listFixtureTags !== null && listFixtureTags.length > 0 && listFixtureTags[0]?.options !== null">
                                    <!-- <el-input class="input-width-tall" id="stepInputFixture"
                                        name="stepInputFixture" v-model.lazy="editedStep.fixtureTag"
                                        @change="toggleFixture(row)"
                                        placeholder="Enter Fixture"></el-input> -->
                                    <el-select class="select-danger" placeholder="Choose Fixture"
                                      v-model="editedStep.fixtureTag" @change="toggleFixture(row)">
                                      <el-option v-for="(option, id) in listFixtureTags[0].options" class="select-danger"
                                        :value="option.value" :label="option.label" :key="id">
                                      </el-option>
                                    </el-select>
                                  </el-form-item>

                                  <label
                                    v-else-if="editedStep.fixture && (listFixtureTags[0]?.options === null || listFixtureTags !== null || listFixtureTags.length > 0)">
                                    Please Write Fixture On App Page </label>
                                  <!-- <div style="margin-left:10px" v-if="editedStep.action === 'command'"> 
                                      <el-tooltip content="Enable Mock">
                                        <h3 class="text-title" style="margin-right: 10px;">Mock </h3>
                                        <el-switch v-model="editedStep.isMockResponse" active-color="#1F213E"
                                          inactive-color="#759DCB"></el-switch>
                                      </el-tooltip>
                                    </div> -->


                                </el-form-item>

                                <el-input v-if="editedStep.isMockResponse" class="input-width-tall" type="textarea"
                                  id="stepInputCommand" name="stepInputCommand" v-model="editedStep.APIMockResponse"
                                  placeholder='Enter expected output' style="margin-bottom: 10px;"></el-input>
                                <div class="edit-accordion-btns">
                                  <button class="add_button" native-type="submit">
                                    {{ modalActionText }}
                                  </button>
                                  <button class="cancel_button" type="button"
                                    @click="selectedItemId = null">Cancel</button>
                                </div>

                              </el-form>
                            </div>
                          </div>


                        </div>
                      </div>
                      <!-- Edit Step End -->
                    </el-card>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </div>
        </div>

        <el-pagination v-if="!startLoading && toShow.length > 0" background class="" layout="prev, pager, next"
          :total="total" :page-size="perPage" :page-sizes="pageSizes" :current-page.sync="currentPage"
          @current-change="handleCurrentChange" />

        <div>
          <modal class="small-modal left-footer" :show.sync="addEditStep">
            <template slot="header">
              <h5 class="modal-title">{{ modalTitle }}</h5>
            </template>
            <div style="width: 100%">
              <button class="add_button" @click="handleShowScreen()" v-if="editedStep.initialPageScreenshot !== ''">
                {{ modalScreenshot }}
              </button>
              <div class="container-modal-screen">
                <img v-if="editedStep.initialPageScreenshot && isShowSceenshot"
                  :src="`data:image/png;base64,${editedStep.initialPageScreenshot}`" alt="screenshot of current step"
                  class="elt--modal_img"
                  @click="previewPictureWithoutSelector(`data:image/png;base64,${editedStep.initialPageScreenshot}`)" />

                <!--<div class = "container-modal-screen">
                <img v-if="editedStep.initialPageScreenshot && isShowSceenshot" :src="`data:image/png;base64,${editedStep.initialPageScreenshot}`"
                  alt="screenshot of current step" class="elt--modal_img" @click="previewPictureWithoutSelector(`data:image/png;base64,${editedStep.initialPageScreenshot}`)"/>
              </div> -->
                <!-- <div v-else-if="!editedStep.initialPageScreenshot && !isShowSceenshot"></div> -->
                <div>
                  <el-form v-loading="stepLoading" @submit.native.prevent="addTestStepAction()">
                    <el-form-item class="cstm-form--insert" v-if="editedIndex === -1" prop="existing step">
                      <label class="el-insert-item__label">Insert new step</label>
                      <el-select class="select-primary cstm-insert" style="display: none;"
                        :placeholder="editedStep.insertItem" v-model="editedStep.insertItem"
                        @change="handleInsertItem()">
                        <el-option v-for="(item, index) in optionsInsert" class="select-primary" :value="item"
                          :label="item" :key="index">
                        </el-option>
                      </el-select>
                      <label class="el-insert-item__label"> existing step</label>
                      <el-select class="select-primary" :placeholder="$t('test_run_steps.new_step')"
                        v-model="editedStep.targetStepId" ref="selectStep" @change="changeStepId()">
                        <el-option v-for="(item, index) in toShow" class="select-primary" ref="stepname"
                          :value="item.stepId" :label="(index + from + 1) + ' - ' + item.stepDescription"
                          :key="item.stepNumber">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <div
                      :class="editedStep.action === 'Put' || editedStep.action === 'Post' || editedStep.action === 'Get' || editedStep.action === 'Delete' || editedStep.action === 'playwright' || editedStep.action === 'command' ? 'edit-short-modal-grid' : 'edit-body-modal-grid'">
                      <el-form-item :label="$t('test_run_steps.action_to_perform')" prop="Action">
                        <el-select class="input-width-tall" popper-class="custom-dropdown-popup-steps"
                          placeholder="Actions" v-model="editedStep.action" @change="handleChangeAction()">
                          <!-- <el-option v-for="option in stepActionMap" :value="option.value" :label="option.label"
                          :key="option.value"> -->
                          <el-option-group v-for="group in stepActionMap" :key="group.label" :label="group.label">
                            <el-option v-for="item in group.options[0]" :key="item.value" :label="item.label"
                              :value="item.value">
                              <!-- {{item.value}} -->
                            </el-option>
                          </el-option-group>
                        </el-select>
                      </el-form-item>
                      <el-col :span="6"
                        v-if="editedStep.action !== 'Put' && editedStep.action !== 'Post' && editedStep.action !== 'Get' && editedStep.action !== 'Delete' && editedStep.action !== 'playwright' && editedStep.action !== 'command'">
                        <el-form-item :label="$t('test_run_steps.selector_type')" prop="prop3">
                          <el-select class="select-danger" :placeholder="$t('test_run_steps.choose_selector_type')"
                            v-model="editedStep.selectorType">
                            <el-option v-for="option in stepSelectorList" class="select-danger" :value="option.value"
                              :label="option.label" :key="option.label">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="18"
                        v-if="editedStep.action !== 'Put' && editedStep.action !== 'Post' && editedStep.action !== 'Get' && editedStep.action !== 'Delete' && editedStep.action !== 'playwright' && editedStep.action !== 'command'">
                        <el-form-item :label="$t('test_run_steps.selector_to_use')">
                          <el-input class="input-width-tall" type="text" id="stepSelector" name="stepSelector"
                            v-model="editedStep.selector" :placeholder="$t('test_run_steps.enter_selector')"></el-input>
                        </el-form-item>
                      </el-col>
                      <div>
                        <el-form-item
                          :label="editedStep.action.includes('click') ? $t('test_run_steps.other_items') : $t('test_run_steps.input_values')"
                          v-if="editedStep.action !== 'Put' && editedStep.action !== 'Post' && editedStep.action !== 'Get' && editedStep.action !== 'Delete' && editedStep.action !== 'playwright' && editedStep.action !== 'command'">
                          <el-input class="input-width-tall" type="text" id="stepInputValue" name="stepInputValue"
                            v-model="editedStep.inputValue" :placeholder="$t('test_run_steps.enter_value')"></el-input>
                        </el-form-item>
                        <el-tooltip content='Get Request| Post URL| Put headers| Post body| Assert success or failure'>
                          <el-form-item label="Command"
                            v-if="editedStep.action === 'Put' || editedStep.action === 'Post' || editedStep.action === 'Get' || editedStep.action === 'Delete' || editedStep.action === 'playwright' || editedStep.action === 'command'">
                            <el-input class="input-width-tall" type="textarea" id="stepInputCommand"
                              :autosize="{ minRows: 6, maxRows: 30 }" name="stepInputCommand"
                              v-model="editedStep.command"
                              placeholder="Get Request| Post URL| Put headers| Post body| Assert success or failure"></el-input>
                          </el-form-item>
                        </el-tooltip>

                      </div>
                    </div>
                    <el-form-item class="enable-switch-cont" prop="prop6">
                      <div>
                        <h3 class="text-title">Enable Step</h3>
                      </div>
                      <el-tooltip content="Enable Step">
                        <el-switch v-model="toggleStepValue" active-color="#1F213E" inactive-color="#759DCB"
                          @change="toggleStep(row)"></el-switch>
                      </el-tooltip>
                      <div style="margin-left:10px">
                        <h3 class="text-title"> Fixture </h3>
                      </div>
                      <el-tooltip
                        :content="editedStep.action === '$pageview' ? 'Not Allowed For Pagview Actions' : 'Enable Tag Fixture'">
                        <el-switch :disabled="editedStep.action === '$pageview' ? true : false"
                          v-model="editedStep.fixture" active-color="#1F213E" inactive-color="#759DCB"></el-switch>
                      </el-tooltip>
                      <el-form-item style="margin-left: 10px"
                        v-if="editedStep.fixture && listFixtureTags !== null && listFixtureTags.length > 0 && listFixtureTags[0]?.options !== null">
                        <!-- <el-input class="input-width-tall" id="stepInputFixture"
                                        name="stepInputFixture" v-model.lazy="editedStep.fixtureTag"
                                        @change="toggleFixture(row)"
                                        placeholder="Enter Fixture"></el-input> -->
                        <el-select class="select-danger" placeholder="Choose Fixture" v-model="editedStep.fixtureTag"
                          @change="toggleFixture(row)">

                          <el-option v-for="(option, id) in listFixtureTags[0].options" class="select-danger"
                            :value="option.value" :label="option.label" :key="id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <label
                        v-else-if="editedStep.fixture && (listFixtureTags[0]?.options === null || listFixtureTags !== null || listFixtureTags.length > 0)">
                        Please Write Fixture On App Page </label>
                    </el-form-item>
                    <!-- <div style="margin-left:10px" v-if="editedStep.action === 'command'"> 
                                      <el-tooltip content="Enable Mock">
                                        <h3 class="text-title" style="margin-right: 10px;"> Mock </h3>
                                        <el-switch v-model="editedStep.isMockResponse" active-color="#1F213E"
                                          inactive-color="#759DCB"></el-switch>
                                      </el-tooltip>
                                    </div> -->
                    <el-input class="input-width-tall" type="textarea" id="stepInputCommand" name="stepInputCommand"
                      v-model="editedStep.APIMockResponse" placeholder='Enter expected output'
                      style="margin-bottom: 10px;"></el-input>
                    <div class="edit-accordion-btns">
                      <button class="add_button" native-type="submit">
                        {{ modalActionText }}
                      </button>
                      <button class="cancel_button" type="button" @click="addEditStep = false">Cancel</button>
                    </div>

                  </el-form>
                </div>
              </div>
            </div>

          </modal>
          <modal class="small-modal" id="var-modal" :show.sync="showVariablesModal" v-loading="loadingVariableList">
            <template slot="header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("test_enhancer.Setup_dynamic_variables") }}
              </h5>
            </template>
            <div class="tblb--row mb-2" v-if="loadingVariable">
              <div class="tblb--inner">
                <img src="/img/loading.gif" alt="loading..." style="height: 64px; width: 64px" />
              </div>
            </div>
            <div v-else>
              <data-list :items="qlyVariables" :customSizes="true" :loading="loadingVariable"
                :emptyText="$t('test_enhancer.no_variable_data')">
                <template #content>
                  <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;text-align: center;"
                    class="custom-header-rows">
                    <div class="elt-row max-width">
                      <h3>VARIABLE</h3>
                    </div>
                    <div class="elt-row max-width">
                      <h3>TYPE</h3>
                    </div>
                    <!-- <div class="elt-row max-width" >
                <h3>DATA</h3>
              </div> -->
                    <div class="elt-row max-width">
                      <h3>SOURCE</h3>
                    </div>
                    <div class="elt-row max-width" style="white-space: nowrap;padding-left: 0 !important;">
                      <h3>ACTIVE</h3>
                    </div>
                    <div class="elt-row max-width">
                      <h3>ACTIONS</h3>
                    </div>

                  </div>
                  <div class="var-table-modal">
                    <div v-for="(elt, index) in qlyVariables" :key="index" class="tblb--row mb-2">
                      <div class="tblb--inner"
                        style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;text-align: center;">
                        <div class="elt-row max-width">
                          <span class="test-run-name">{{ elt.name }}</span>
                        </div>
                        <div class="elt-row max-width">
                          <span class="test-time-cont">{{ elt.type }}</span>
                        </div>
                        <!-- <div class="elt-row max-width">
                      <el-tooltip content="shows all data" placement="top">
                        <span
                          :id="`var_tag_data_dynamic_${index}`"
                          class="var--tag"
                          style="max-width: 100%; clear: both"
                          @click="varTagchek(`var_tag_data_dynamic_${index}`)"
                        >
                          {{ elt.init_value }}
                        </span>
                      </el-tooltip>
                    </div> -->
                        <div class="elt-row max-width">
                          <el-row v-if="elt.source === 'datafile'">
                            <el-tag key="datafile" size="small"> datafile </el-tag>
                          </el-row>
                          <el-row v-else-if="elt.source === 'salesforce'">
                            <el-tag key="salesforce" type="warning" size="small">
                              salesforce
                            </el-tag>
                          </el-row>
                          <el-row v-else>
                            <el-tag key="testcase" class="custom-alert-tag-syn" size="small">
                              synthetic
                            </el-tag>
                          </el-row>
                        </div>
                        <div class="elt-row max-width">
                          <el-row v-if="elt.status === 'active'">
                            <el-tag key="testcase" type="success" size="small">
                              Active
                            </el-tag>
                          </el-row>
                          <el-row v-else>
                            <el-tag key="salesforce" type="danger" size="small">
                              Inactive
                            </el-tag>
                          </el-row>
                        </div>
                        <div class="elt-row max-width" style="display: flex;gap: 5px">
                          <el-tooltip content="Edit" placement="top">
                            <el-button style="margin-left: 10px" type="primary" icon="el-icon-edit"
                              @click.stop="handleEditVariable(elt)" circle plain size="small"
                              :disabled="elt.source !== 'synthetic'"></el-button>
                          </el-tooltip>
                          <el-tooltip content="Change Source" placement="top">
                            <el-button type="success" icon="el-icon-sort" @click.stop="changeSourceVariable(elt)" circle
                              plain size="small" :disabled="elt.source == 'datafile'
            ? false
            : inDatafile(elt.name)
            "></el-button>
                          </el-tooltip>
                          <el-tooltip content="Delete" placement="top">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="handleDeleteVariable(elt)"
                              circle plain size="small" :disabled="elt.source !== 'synthetic'"></el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </data-list>
            </div>
            <template slot="footer">
              <button class="add_button" @click="addVariableModal">
                Add Variable
              </button>
            </template>
          </modal>
        </div>

        <div class="video-modal-container photo-modal">
          <modal :show.sync="viewPicture">
            <template slot="header">
              <h5 class="modal-title"></h5>
            </template>
            <img v-if="testStepBigSS === ''" src="../../../assets/images/loader.gif" alt="Loading" width="50px"
              height="50px" class="gif-img" />
            <template v-else>
              <template v-if="isMultiple">
                <div class="mr-2">
                  <img :src="testStepBigSS[0]" alt="Preview Image" class="p--img" />
                </div>
                <div>
                  <img :src="testStepBigSS[1]" alt="Preview Image" class="p--img" />
                </div>
              </template>
              <img v-else :src="testStepBigSS" alt="Preview Image" class="p--img" />
            </template>
          </modal>
          <modal :show.sync="showTestEdit" modal-classes="modal-danger" modal-content-classes="bg-gradient-danger">
            <h6 slot="header" class="modal-title">Your attention is required</h6>

            <div class="py-3 text-center">
              <i class="ni ni-bell-55 ni-3x"></i>
              <h4 class="heading mt-4">You should read this!</h4>
              <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
            </div>


            <template slot="footer">
              <base-button type="white">Ok, Got it</base-button>
              <base-button type="link" class="text-white ml-auto" @click="modals.notice = false">Close</base-button>
            </template>

          </modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import swal from "sweetalert2";
import moment from "moment";

export default {
  name: "testRunSteps",
  components: { draggable },
  props: {
    items: {
      type: Object,
      default: undefined,
    },
    toggleTestBtn: Function,
  },
  data() {
    return {
      last_change: null,
      isExpendit: false,
      stepLoading: false,
      editedStep: {
        targetStepId: undefined,
        selector: undefined,
        selectorType: undefined,
        action: "",
        inputValue: "",
        command: "",
        insertItem: "after",
        fixture: false,
        fixtureTag: "",
        APIMockResponse: "",
        isMockResponse: false,
      },
      listFixtureTags: [],
      optionsInsert: [
        'before',
        'after'
      ],
      isChangedStep: false,
      newStep: {
        targetStepId: undefined,
        selector: undefined,
        selectorType: undefined,
        action: "",
        inputValue: "",
      },
      stepActionMap: [
        {
          label: "Common Actions",
          options: [{
            Playwright: {
              value: "playwright",
              label: "playwright api method",
              requiresValue: false,
              requiresSelector: false,
              requiresBody: false,
            },
            command: {
              value: 'command',
              label: "Custom Command",
              requiresValue: false,
              requiresSelector: false,
              requiresBody: false,
            },
            click: {
              value: "click",
              label: "Click on a page element",
              requiresValue: false,
              requiresSelector: true,
            },
            send_keys: {
              value: "send_keys",
              label: "Type text into a form element",
              requiresValue: true,
              requiresSelector: true,
            },
          }]
        },
        {
          label: "Api Call",
          options: [{
            Playwright: {
              value: "playwright",
              label: "playwright api method",
              requiresValue: false,
              requiresSelector: false,
              requiresBody: false,
            },
            Get: {
              value: "Get",
              label: "get api method",
              requiresValue: false,
              requiresSelector: false,
              requiresBody: false,
            },
            Post: {
              value: "Post",
              label: "post api method",
              requiresValue: false,
              requiresSelector: false,
              requiresBody: true,
            },
            Put: {
              value: "Put",
              label: "put api method",
              requiresValue: false,
              requiresSelector: false,
              requiresBody: false,
            },
            Delete: {
              value: "Delete",
              label: "delete api method",
              requiresValue: false,
              requiresSelector: false,
              requiresBody: false,
            },
          }
          ],
        },
        {
          label: "Custom Command Extension",
          options: [
            {
              command: {
                value: 'command',
                label: "Custom Command",
                requiresValue: false,
                requiresSelector: false,
                requiresBody: false,
              }
            }]

        },
        {
          label: "Event Click",
          options: [
            {
              click: {
                value: "click",
                label: "Click on a page element",
                requiresValue: false,
                requiresSelector: true,
              },
              double_click: {
                value: "double_click",
                label: "Double click on a page element",
                requiresValue: false,
                requiresSelector: true,
              },
              context_click: {
                value: "context_click",
                label: "Right click on a page element",
                requiresValue: false,
                requiresSelector: true,
              },
            }
          ]
        },
        {
          label: "For Form",
          options: [
            {
              form_select: {
                value: "form_select",
                label: "Choose an option from a select control element",
                requiresValue: false,
                requiresSelector: true,
              },
              form_submit: {
                value: "form_submit",
                label: "Submit form on page",
                requiresValue: false,
                requiresSelector: true,
              },
              send_keys: {
                value: "send_keys",
                label: "Type text into a form element",
                requiresValue: true,
                requiresSelector: true,
              },
            }
          ]
        },
        {
          label: "Alert/Popup",
          options: [
            {
              dismiss_next_alert: {
                value: "dismiss_next_alert",
                label: "Click 'Cancel' on next alert popup",
                requiresValue: false,
                requiresSelector: false,
              },
              accept_next_alert: {
                value: "accept_next_alert",
                label: "Click 'OK' on next alert popup",
                requiresValue: false,
                requiresSelector: false,
              },
            }
          ]
        },
        {
          label: "Other",
          options: [
            {
              url_open: {
                value: "url_open",
                label: "Navigate to page at given URL",
                requiresValue: true,
                requiresSelector: false,
              },

              frame_select: {
                value: "frame_select",
                label: "Switch to another frame in the webpage",
                requiresValue: false,
                requiresSelector: true,
              },

              mouse_over: {
                value: "mouse_over",
                label: "Hover mouse over a page element",
                requiresValue: false,
                requiresSelector: true,
              },

              edit_content: {
                value: "edit_content",
                label: "Edit content of a page element (for rich text editor fields)",
                requiresValue: false,
                requiresSelector: true,
              },


              verify_text: {
                value: "verify_text",
                label: "Verify that an element on page contains the given text",
                requiresValue: true,
                requiresSelector: true,
              },
              capture_value: {
                value: "capture_value",
                label: "Capture element on page to a variable",
                requiresValue: true,
                requiresSelector: true,
              },
              pause: {
                value: "pause",
                label: "Pause for the given interval (in milliseconds)",
                requiresValue: true,
                requiresSelector: false,
              },
            }
          ]
        }
      ],
      stepSelectorList: [
        { value: "css selector", label: "CSS Selector" },
        { value: "xpath", label: "Xpath" },
        { value: "api", label: "Api" },
      ],
      showTestEdit: false,
      filteredResults: [],
      query: null,
      isMultiple: false,
      sharedJSON: undefined,
      isSharing: "false",
      dataView: "thumb",
      stepSelector: null,
      stepInputValue: null,
      toggleStepValue: null,
      toggleTagFixture: null,
      loading: true,
      testStepBigSS: "",
      stepIdForInsertingNewStep: "",
      selectedItemId: null,
      editAccordionForStep: "false",
      selectorDetails: [],
      toggleEditStepModal: false,
      enabled: true,
      dragging: false,
      total: 0,
      perPage: 5,
      currentPage: 1,
      pageSizes: [5, 10, 25],
      toShow: [],
      to: 0,
      from: 0,
      addId: -1,
      adding: false,
      viewPicture: false,
      requestingForData: true,
      startLoading: true,
      activeItem: "test_run_steps",
      //For Test Enhancer
      RLJson: {},
      RLtestcase: {
        sequence: {},
      },
      RLdatafile: {},
      id: "",
      TestEnhancerItems: "",
      testH: {},
      newTest: {},
      editedTest: {},
      editedIndex: -1,
      addEditStep: false,
      showVariablesModal: false,
      variablesModal: false,
      currentVariables: undefined,
      loadingVariableList: false,
      loadingVariable: false,
      loadingVariableCreation: false,
      qlyVariables: [],
      isActionApi: false,
      modalScreenshot: "Show Screenshot",
      isShowSceenshot: false,
      currentPageScreenshot: '',
      afterPageScreenshot: '',
      editDesc: '',
      isEditDesc: false,
      selectedStepId: null,
      loadingTestFile: false,
      percentage: 0,
      projectDetail: null,
      colors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ],
      dowloadPrompt: '',
      chosenDownloadFile: 'cucumber',
      updateValidate: false,
      showGenTest: false,
      showDownloadTest: false,
      btnGenTest: false,
      featureFile: '',
      userPrompt: '',
      showProgressBar: false,
      edittingPrompt: false,
      showEditPrompt: '',
      cleanedPrompt: '',
    };
  },
  beforeCreate() {
    this.startLoading = true
  },
  methods: {
    showDetailSession() {
      this.$router.push({
        name: "Details Session",
        params: {
          id: this.$route.params.id,
          testSuiteId: this.$route.params.testSuiteId,
          session_id: this.$route.params.session_id,
          run_name: this.$route.params.run_name,
          start_time: this.$route.params.start_time,
          recording_duration: this.$route.params.recording_duration,
          name: this.$route.params.page_name
        },
        query: {
          wring: true
        }
      })
    },
    //#region helpers
    selectorInfo(steps_row) {
      let steps = steps_row;
      let indexToTruncate = steps.indexOf(":");
      let textWithSelectorInfo = steps.slice(indexToTruncate + 1);
      return textWithSelectorInfo;
    },
    processCheck() {

      if (
        this.editedStep.targetStepId === undefined ||
        this.editedStep.action === undefined
      ) {
        return "Action and target step cannot be empty";

      }

      if (!(this.editedStep.action in this.stepActionMap)) {
        return `Unrecognized action: ${this.editedStep.action}`;
      }

      const actionLabel = this.stepActionMap[this.editedStep.action]["label"];

      if (
        this.editedStep.action && this.editedStep.action in this.stepActionMap &&
        this.stepActionMap[this.editedStep.action]["requiresSelector"] &&
        this.editedStep.selector === undefined
      ) {
        return `Selector type and selector input can't be empty for action: ${actionLabel}`;
      }

      if (
        this.editedStep.action && this.editedStep.action in this.stepActionMap &&
        this.stepActionMap[this.editedStep.action]["requiresSelector"] &&
        this.editedStep.selector && this.editedStep.selector.trim().length == 0
      ) {
        return `Selector type and selector input can't be empty for action: ${actionLabel}`;
      }

      if (
        this.editedStep.action && this.editedStep.action in this.stepActionMap &&
        this.stepActionMap[this.editedStep.action]["requiresValue"] &&
        this.editedStep.inputValue === undefined
      ) {
        return `Input value can't be empty for action: ${actionLabel}`;
      }

      if (
        this.editedStep.action && this.editedStep.action in this.stepActionMap &&
        this.stepActionMap[this.editedStep.action]["requiresValue"] &&
        this.editedStep.inputValue && this.editedStep.inputValue.trim().length == 0
      ) {
        return `Input value can't be empty for action: ${actionLabel}`;
      }

      if (
        this.editedStep.action && this.editedStep.action in this.stepActionMap &&
        this.stepActionMap[this.editedStep.action]["requiresBody"] &&
        this.editedStep.request_payload && this.editedStep.request_payload.length == 0
      ) {
        return `body value can't be empty for action: ${actionLabel}`;
      }

      return false;
    },
    showDropdown() {
      this.$refs.btnDownloadScripts.click()
    },
 formatGherkin(text) {
    const featureFileContent = text;
    const lines = featureFileContent.split('\n');
    let formattedText = '';
    lines.forEach(line => {
      line = line.trim();
      if (line.startsWith('Feature:')) {
        
        formattedText += `${line}\n`; 
      } else if (line.startsWith('Scenario:')) {
        formattedText += `${line}\n`;
      } else if (line.startsWith('Given') || line.startsWith('When') || line.startsWith('Then') || line.startsWith('And')) {
        formattedText += `${line}\n`;
      } else {
        formattedText += `${line}\n`;
      }
    });

    return formattedText.trim();
  },
  colorOutput(text=''){
      let commands = ['And','When','Given','Then','Feature','Scenario']
      if(text != ''){
        for(let i=0;i < commands.length;i++){
        const regex = new RegExp(commands[i], 'g');
        if(i == 4){
          text = text.replace(regex,`<p2 class='color-words'>${commands[i]}</p2>`)
        }else{
          text = text.replace(regex,`<br><p2 class='color-words'>${commands[i]}</p2>`)
        }
      }
      text = text.replace('gherkin','').replaceAll('`','').replace('{','').replaceAll('\n','').replace(' }','').replace('feature_file','').replace('}','')
      return text
      }
    },
    handleEditPrompt() {
      this.edittingPrompt = true
      this.cleanedPrompt = this.showEditPrompt
      this.showEditPrompt = this.showEditPrompt.replace('gherkin','').replaceAll('`','').replace('{','').replace(' }','').replace('feature_file','').replace('}','')
    },
    closeEdittingPrompt () {
     this.edittingPrompt = false;
     this.showEditPrompt = this.cleanedPrompt
    },
    modifyEdittingPrompt() {
      this.edittingPrompt = false;
      this.dowloadPrompt = this.colorOutput(this.showEditPrompt)
      
    },
async downloadTestFile(type) {
    // Check if we need to handle streaming for 'preview' type
    const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/session/v1/testgen`; // Ensure this points to your Tornado backend
    this.edittingPrompt = false;
    if (type === 'preview') {
        this.loadingTestFile = true;
        this.btnGenTest = true;
        this.dowloadPrompt = ''; // Clear previous content

        // Set the API endpoint for your Tornado backend's `testgen` function

        // Prepare the query parameters
        const projectId = this.$route.params.projectId;
        const sessionId = this.$route.params.session_id;
        const params = new URLSearchParams();
        params.append("project_id", projectId);
        params.append("session_id", sessionId);
        params.append("type", type);
        params.append("engine", "ai");
        params.append("isValidate", this.updateValidate);

        if (this.userPrompt !== "" && this.userPrompt.trim() !== "") {
            params.append("prompt", this.userPrompt);
        }
        

        try {
            console.log('Starting fetch to backend:', url, 'with params:', params.toString());

            // Use fetch to handle the response stream
            const token = localStorage.getItem("vue-authenticate.access_token")
            const response = await fetch(`${url}?${params.toString()}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            console.log('Fetch response status:', response.status);
            console.log(response)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Use native ReadableStream API to read the response
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let accumulatedText = ''; // Accumulate the streamed text
            let buffer = '';
             const processStream = async () => {
                    while (true) {
                      this.loadingMessage = false
                        const { done, value } = await reader.read();
                        if (done){
                          break;
                        };

                        // Decode and add to buffer
                        buffer += decoder.decode(value, { stream: true });

                        // Check for new lines
                        const lines = buffer.split('\n');
                        let test
                        buffer = lines.pop(); // Save the last line (might be incomplete)

                        lines.forEach(async line => {
                            const trimmedLine = line.trim(); // Remove extra whitespace
                            if (trimmedLine) {
                                try {
                                    const parsed = JSON.parse(trimmedLine);
                                    
                                    if (parsed.content) {
                                      await this.delay(1)
                                      this.showEditPrompt += parsed.content
                                      this.showEditPrompt = this.showEditPrompt.replaceAll('\\n','\n').replace('"feature_file": "','').replace('"feature": "','').replaceAll('\\','')
                                      parsed.content = this.colorOutput(parsed.content)
                                      this.dowloadPrompt += parsed.content;
                                      this.dowloadPrompt = this.dowloadPrompt.replaceAll('\\n','').replace('"feature_file": "','').replace('"feature": "','').replaceAll('\\','')
                                      this.loadingTestFile = false;
                                      this.btnGenTest = false;
                                      
                                        // Ensure the UI updates immediately
                                        this.$nextTick(() => {
                                            const chatBox = document.querySelector(".chat-box");
                                            if (chatBox) {
                                                chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                                            }
                                        });
                                    }
                                } catch (err) {
                                    console.warn("Skipping malformed line:", line, err); // Log and continue
                                }
                            }
                        });
                    }
                    
                    // Process any remaining data in the buffer
                    if (buffer.trim()) {
                        try {
                            const parsed = JSON.parse(buffer.trim());
                            if (parsed.content) {
                              await this.delay(1)
                              this.showEditPrompt += parsed.content
                              this.showEditPrompt = this.showEditPrompt.replaceAll('\\n','\n').replace('"feature_file": "','').replace('"feature": "','').replaceAll('\\','')
                              parsed.content = this.colorOutput(parsed.content)
                              this.dowloadPrompt += parsed.content;
                              this.cleanedPrompt =  this.showEditPrompt
                              this.dowloadPrompt = this.dowloadPrompt.replaceAll('\\n','').replace('"feature_file": "','').replace('"feature": "','').replaceAll('\\','')
                              this.loadingTestFile = false;
                              this.btnGenTest = false;
                                // Ensure the UI updates immediately
                                this.$nextTick(() => {
                                    const chatBox = document.querySelector(".chat-box");
                                    if (chatBox) {
                                        chatBox.scrollTop = chatBox.scrollHeight; // Scroll to bottom
                                    }
                                });
                            }
                        } catch (err) {
                            console.error("Error parsing JSON from stream at end:", err);
                        }
                    }
                    
                };


            await processStream(); // Process the stream
        } catch (error) {
            console.error('Error during streaming:', error);
            this.$notify({
                type: "danger",
                message: "Error during streaming. Please try again.",
            });
            this.loadingTestFile = false;
            this.btnGenTest = false;
        }
        
        return; // Exit after handling 'preview' type
    }
// For all other types, handle the download logic
this.loadingTestFile = true;
const projectId = this.$route.params.projectId;
const sessionId = this.$route.params.session_id;
if(type == 'cucumber'){
  type = 'cucumber_natural'
}
const params = new URLSearchParams();
params.append("project_id", projectId);
params.append("session_id", sessionId);
params.append("type", type);
params.append("engine", "ai");
params.append("isValidate", this.updateValidate);

const date = new Date();
const fileName = `${this.$route.params.nameProject}-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.wring`;
this.showProgressBar = true
this.percentage = 0;
const timeout = setInterval(() => {
    if (this.percentage < 90) this.percentage += 10;
}, 1000);
if(type=='cucumber_natural' && this.showEditPrompt !== "" && this.showEditPrompt.trim() !== ""){
          let feature_file = document.getElementById('message-edit').innerText
          if(this.showEditPrompt && this.showEditPrompt != ''){
            params.append("feature_file", feature_file.replace('gherkin',''));
            params.append("prompt", this.userPrompt);
          }
          
        }else{
          params.append("prompt", this.showEditPrompt);
        }

if (this.editedStep.selectorType === 'api') {
    this.$notify({
        type: "warn",
        message: "Your Test Contains API Calls And It Can Take A While, Up To 10 Minutes"
    });
}

try {
    const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/session/v1/testgen`; // Ensure the correct API endpoint

    console.log('Starting regular file download with axios:', params.toString());
    const token = localStorage.getItem("testgold.aioToken")
    const response = await axios.get(`${url}?${params.toString()}`, {
        responseType: type === 'cucumber' || type === 'cucumberconfig' || type === "sensetalk_zip" || 'cucumber_natural' ? 'blob' : 'json'
    });
    
    console.log('Axios response received:', response);

    let responseData;
    let blobData;
    if (response.data.http_status === 429) {
        let url = window.location.hostname;
        if (this.userName.tier_name === 'enterprise') {
            this.$notify({
                type: "warn",
                duration: 6000,
                message: `You are above your usage limit.`,
            });
        } else {
            this.$notify({
                type: "warn",
                duration: 6000,
                message: `You are above your usage limit. Upgrade your plan to ${this.userName.tier_name === 'community' ? 'Pro' : 'Enterprise'} to continue to use the product, <a href='https://${url}/details/subscription'>https://${url}/details/subscription</a>`,
            });
        }
    } else {
        if (type === 'cucumber' || type === 'cucumberconfig' || type === "sensetalk_zip" || type == 'cucumber_natural') {
            blobData = new Blob([response.data], { type: 'application/zip' });
        } else {
            responseData = response.data;
            if (type === 'wringr') {
                responseData = JSON.stringify(response.data);
            }
            blobData = new Blob([responseData], { type: 'application/zip' });
        }
    }
    console.log(blobData)
    console.log("BLOBS")
    const downloadUrl = window.URL.createObjectURL(blobData);
    const link = document.createElement('a');
    let extension = ".wringr";

    switch (type) {
        case "sensetalk":
            extension = ".script";
            break;
        case "cypress":
            extension = ".cy";
            break;
        case "selenium":
            extension = ".py";
            break;
        case "playwright":
            extension = ".spec.js";
            break;
        case "puppeteer":
            extension = ".py";
            break;
        case "cucumber":
        case "cucumberconfig":
        case "sensetalk_zip":
        case "cucumber_natural":
            extension = '.zip';
            break;
    }

    link.href = downloadUrl;
    link.setAttribute('download', fileName + extension);
    document.body.appendChild(link);
    link.click();

    this.$notify({
        type: "success",
        message: "File downloaded successfully."
    });

    this.percentage = 100;
    this.btnGenTest = false;
} catch (err) {
    console.error("Error during file download:", err);
    this.$notify({
        type: "danger",
        message: "Cannot Download File, Try To Reload Page."
    });
    this.btnGenTest = false;
    clearInterval(timeout);
} finally {
    this.loadingTestFile = false;
    this.showProgressBar = false
    clearInterval(timeout);
    console.log("Download process complete.");
}
},

    formatISODateTime(val) {
      let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let momentDt = moment(val);
      let day_display = "";
      let time_display = "";

      if (time_zone.toLowerCase().includes("america")) {
        day_display = momentDt.format("MMM Do YYYY");
        time_display = momentDt.format("LT");
      } else {
        day_display = momentDt.format("Do MMM YYYY");
        time_display = momentDt.format("HH:mm");
      }

      time_zone = moment.tz(time_zone).zoneAbbr();
      if (time_zone === "America/Los_Angeles") {
        time_zone = "PDT";
      }

      return day_display + " " + time_display + " " + time_zone;

    },
    toggleVariableModal() {
      this.showVariablesModal = true;
    },
    async longPollingData(polling, time_start, finish_time) {
      let current_time = new Date()
      current_time = current_time.toISOString()
      const res = await axios.get(`${process.env.VUE_APP_API_URL_PREFIX}/pageviews/sessionupdate`)
      if (time_start < res.data.last_update) {
        clearInterval(polling)
        await Promise.all([this.getTestRunSteps()]);
        this.$notify({
          type: 'success',
          message: 'Screenshots Updated!'
        })
      } else if (finish_time <= current_time) {
        clearInterval(polling)
        this.$notify({
          type: 'danger',
          message: 'Time-out. Try again later...'
        })
        this.startLoading = false
      }



    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async toggleRunScreen() {
      this.startLoading = true;
      //time
      let time_start = new Date();
      let finish_time = new Date(time_start.getTime() + 1 * 60000)
      time_start = time_start.toISOString()
      finish_time = finish_time.toISOString()

      if (this.$route.params.projectId) {
        if (this.$route.params.session_id) {
          let session_id = this.$route.params.session_id;
          let projectId = this.$route.params.projectId;

          const uri = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/session/v1/validate/${projectId.replace(/\s+/g, '')}/${session_id.replace(/\s+/g, '')}`;
          const res = await axios.post(uri);

          if (res.status === 200) {
            this.total = res.data.totalSteps;
            this.$notify({
              type: "success",
              message: `Re-processing started!`,
            });
          } else {
            this.$notify({
              type: "danger",
              message: `Oops, Cannot load screen!`,
            });
          }
        }
        //checking database changes
        let polling = setInterval(() => {
          this.longPollingData(polling, time_start, finish_time)
        }, 2000)
        this.requestingForData = false;
      }
    },
    routeToVideo() {
      this.$router.push({
        name: "Details Session",
        params: {
          id: this.$route.params.projectId,
          testSuiteId: this.$route.params.testSuiteId,
          session_id: this.$route.params.session_id,
          run_name: this.$route.params.run_name,
          start_time: this.$route.params.start_time,
          recording_duration: this.$route.params.recording_duration,
          name: this.$route.params.nameProject
        },

      });
    },
    varTagchek(id) {
      let el = document.getElementById(id);
      el.classList.toggle("var--tag");
    },
    addVariableModal() {
      // (this.editedVariable = {
      //     name: undefined,
      //     type: "email",
      //     data: undefined,
      //     init_value: undefined,
      //     isFixed: undefined,
      //     source: undefined,
      //     status: undefined,
      // }),
      //     (this.launch_is_active = false);
      // this.showVariablesModal = false;
      // this.showAddVariableModal = true;
      // this.modalType = "add";
    },
    inDatafile(name) {
      // const activeDatafile = Object.entries(this.datafileVariable).map(
      //     (item) => {
      //         return item[0];
      //     }
      // );
      // if (activeDatafile.includes(name)) return false;
      // return true;
    },
    async handleDeleteVariable(elt) {
      // this.editedVariableIndex = this.qlyVariables.findIndex(
      //     (v) => v.name === elt.name
      // );
      // this.loadingVariableList = true;
      // const _query = `var_name=${elt.name}`;
      // const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/variables/${this.id}?${_query}`;
      // const res = await axios.delete(uri);
      // if (res.status === 200) {
      //     this.qlyVariables = [...this.qlyVariables].filter(
      //         (_, id) => id !== this.editedVariableIndex
      //     );
      //     this.$notify({
      //         type: "success",
      //         message: `successfully deleted ${elt.name} variable`,
      //     });
      // } else {
      //     this.$notify({
      //         type: "danger",
      //         message: `Unable to delete ${elt.name} variable, check network`,
      //     });
      // }
      // this.loadingVariableList = false;
    },
    handleEditVariable(elt) {
      // this.oldVariable = Object.assign({}, elt);
      // this.editedVariable = Object.assign({}, elt);
      // this.editedVariableIndex = this.qlyVariables.findIndex(
      //     (v) => v.name === elt.name
      // );
      // this.showAddVariableModal = true;
      // this.modalType = "edit";
    },
    async changeSourceVariable(elt) {
      // const _key = elt.name;
      // delete elt.name;
      // delete elt.isFixed;
      // this.oldVariable = Object.assign({}, elt);
      // this.editedVariable = {
      //     ...elt,
      //     status: elt.status === "active" ? "disabled-source" : "active",
      // };
      // const payload = {
      //     new_variable: { [_key]: this.editedVariable },
      //     old_variable: { [_key]: this.oldVariable },
      //     action: "datafile_edit",
      // };
      // await this.updateVariable(payload);
      // this.showAddVariableModal = false;
    },
    formatDate(val) {
      let day_display = "";
      let time_display = "";
      let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (time_zone.toLowerCase().includes("america")) {
        day_display = moment(val * 1000).format("MMM Do YYYY");
        time_display = moment(val * 1000).format("LT");
      } else {
        day_display = moment(val * 1000).format("Do MMM YYYY");
        time_display = moment(val * 1000).format("HH:mm");
      }

      time_zone = moment.tz(time_zone).zoneAbbr();
      if (time_zone === "America/Los_Angeles") {
        time_zone = "PDT";
      }

      return day_display + " " + time_display + " " + time_zone;
    },
    saveDataView(val) {
      this.dataView = val;
      sessionStorage.setItem("test-data-view", this.dataView);
    },
    // handleEditDesc(row, index) {
    //   this.isEditDesc = true
    //   this.selectedStepId = index
    //   this.editDesc = row.stepDescription
    //   this.editedStep = Object.assign(
    //       {},
    //       {
    //         stepId: row.stepId,
    //         targetStepId: row.stepId,
    //         selector: row.selector,
    //         selectorType: row.selectorType,
    //         action: row.action,
    //         inputValue: row.inputValue,
    //         body: row.request_payload ? elt.request_payload : {},
    //         insertItem: 'after',
    //         command: row.selector,
    //         initialPageScreenshot: row.initialPageScreenshot,
    //         fixture: row.fixtureTag === '' || row.fixtureTag === undefined ? false : true,
    //         fixtureTag: row.fixtureTag,
    //         APIMockResponse: row.APIMockResponse,
    //         isMockResponse: row.APIMockResponse === '' || row.APIMockResponse === undefined ? false : true,
    //       }
    //     );
    // },
    async descSet() {
      this.selectedStepId = null
      this.editedStep.command = this.editDesc
      this.editedIndex = 0
      await this.addTestStepAction()

    },
    parseJwt(token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    toggleTestButton(a) {
      // This function is conditionally rendering variable buttons on tabs card
      this.toggleTestBtn(a);
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
      this.toggleTestButton(this.activeItem);
    },
    handleShowScreen() {
      if (!this.isShowSceenshot) {
        this.modalScreenshot = "Hide Screenshot"
        this.isShowSceenshot = true
      } else {
        this.modalScreenshot = "Show Screenshot"
        this.isShowSceenshot = false
      }
    },
    handleInsertItem() {
      if (this.editedStep.insertItem === "before") {
        console.log("before")
        this.editedStep.initialPageScreenshot = this.currentPageScreenshot
      } else if (this.editedStep.insertItem === "after") {
        this.editedStep.initialPageScreenshot = this.afterPageScreenshot
      }
    },
    handleAddEditStepModal(elt, id, isEdit) {

      this.editedIndex = typeof isEdit !== 'undefined' ? (isEdit === false ? -1 : 0) : -1;
      // console.log("Here!!!", this.editedIndex);
      if (elt) {
        // this.selectedItemId = this.selectedItemId !== id ? id : null;
        this.toggleStepValue = elt.isActive;
        this.currentPageScreenshot = elt.initialPageScreenshot
        this.afterPageScreenshot = this.toShow[id + 1].initialPageScreenshot
        this.editedStep = Object.assign(
          {},
          {
            stepId: elt.stepId,
            targetStepId: elt.stepId,
            selector: elt.selector,
            selectorType: elt.selectorType,
            action: elt.action,
            inputValue: elt.inputValue,
            body: elt.request_payload ? elt.request_payload : {},
            insertItem: 'after',
            command: '',
            initialPageScreenshot: this.editedIndex !== -1 ? elt.initialPageScreenshot : this.toShow[id + 1].initialPageScreenshot,
          }
        );
        console.log({ editStep: this.editedStep });
      }
      this.addEditStep = true;
    },
    handleChangeAction() {

    },
    changeStepId() {
      this.isChangedStep = true
    },
    openEditAccordion(row, id, isEdit) {
      this.adding = false
      // this.editedIndex = id;
      this.selectedItemId = this.selectedItemId !== id ? id : null;

      this.editedIndex = typeof isEdit !== 'undefined' ? (isEdit === false ? -1 : 0) : -1;
      const fixture = row.customjson !== null ?
        row.customjson.tags ? row.customjson.tags : ''
        : ''
      // console.log("Here!!!", this.editedIndex);
      if (row) {
        // this.selectedItemId = this.selectedItemId !== id ? id : null;
        this.toggleStepValue = row.isActive;
        this.currentPageScreenshot = row.initialPageScreenshot
        if (this.toShow[id + 1] > this.toShow.length) {
          this.afterPageScreenshot = this.toShow[id + 1].initialPageScreenshot
        } else {
          this.afterPageScreenshot = null
        }

        this.editedStep = Object.assign(
          {},
          {
            customjson: row.customjson,
            stepId: row.stepId,
            targetStepId: row.stepId,
            selector: row.selector,
            selectorType: row.selectorType,
            action: row.action,
            inputValue: row.inputValue,
            body: row.request_payload ? elt.request_payload : {},
            insertItem: 'after',
            command: row.selector,
            initialPageScreenshot: this.editedIndex !== -1 ? row.initialPageScreenshot : this.toShow[id + 1].initialPageScreenshot,
            fixture: fixture === '' ? false : true,
            fixtureTag: fixture,
            APIMockResponse: row.APIMockResponse,
            isMockResponse: row.APIMockResponse === '' || row.APIMockResponse === undefined ? false : true,
            stepDescription: row.stepDescription
          }
        );
        console.log({ editStep: this.editedStep });
      }
    },
    async handleCurrentChange(val) {
      this.currentPage = val;
      this.editedIndex = -1;
      this.selectedItemId = -1;
      this.editedStep = Object.assign({}, this.newStep);
      await this.getTestRunSteps();
      this.from = (val - 1) * this.perPage;
      this.to = parseInt(this.from) + parseInt(this.perPage);
    },
    async handleSizeChange(val) {
      this.perPage = val;
      await this.handleCurrentChange(this.currentPage);
    },
    handleAddStep(id, row) {
      console.log({ id, row });
      this.selectedItemId = null
      this.editedIndex = -1;
      this.stepIdForInsertingNewStep = row.targetStepId;
      this.adding = !this.adding;
      this.editedStep = Object.assign({}, this.newStep);
      const _par = document.querySelector(".trsl--parent");
      if (this.adding) {
        if (_par.children.length === this.perPage) {
          const _addForm = document.getElementById("add-form");
          _addForm.classList.add("trsl--to_remove");
          _par.insertBefore(_addForm, _par.children[id + 1]);
        } else {
          const _addForm = document.querySelector(".trsl--to_remove");
          _par.removeChild(_addForm);
          _par.insertBefore(_addForm, _par.children[id + 1]);
        }
      }
    },
    //#endregion

    //#region async
    async updateReorder(evt) {
      const stepId = evt.draggedContext.element.stepId;
      const newStepNumber = evt.draggedContext.futureIndex + this.from + 2;
      let projectId = this.$route.params.projectId;
      let session_id = this.$route.params.session_id;
      // const newStepNumber = evt.draggedContext.element.$id + this.from ;
      // let requestId = this.items.requestId;
      const payload = {
        stepEdits: [
          {
            op: "reorder",
            stepId: stepId,
            newStepNumber: Number(newStepNumber),
          },
        ],
      };

      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return (status === 400) || (status >= 200 && status < 300);
        }
        await axios
          .put(
            process.env.VUE_APP_API_URL_PREFIX +
            `/pageviews/session/v1/steps/${projectId.replace(/%20/g, '').replace(/\s/g, '')}/${session_id.replace(/%20/g, '').replace(/\s/g, '')}`,
            payload
          )
          .then((response) => {
            if (response.data.status === 200 || response.data.status === "success") {
              this.getTestRunSteps();
              console.log("eevt", evt)
              this.$notify({
                type: "success",
                message: `${response.data.message}`,
              });
            } else {
              this.getTestRunSteps();

              this.$notify({
                type: "danger",
                message: `${response.data.message}`,
              });
            }
          });
      } catch (error) {
        console.log(error)
        if (error.response) {
          this.$notify({
            type: "danger",
            message: `${error.response.statusText}`,
          });
        }
      }
    },
    async toggleStep(row) {
      let session_id = this.$route.params.session_id;
      let projectId = this.$route.params.projectId;
      console.log("ROW", row)
      const payload = {
        stepEdits: [
          {
            op: "toggle_active",
            stepId: row.stepId,
            isActive: this.toggleStepValue,
          },
        ],
      };
      try {
        await axios
          .put(
            process.env.VUE_APP_API_URL_PREFIX +
            `/pageviews/session/v1/steps/${projectId.replace(/%20/g, '').replace(/\s/g, '')}/${session_id.replace(/%20/g, '').replace(/\s/g, '')}`,
            payload
          )
          .then((response) => {
            this.$notify({
              type: "success",
              message: `${response.data.message}`,
            });
          });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `${error.response.message}`,
        });
      }
    },
    toggleFixture(row) {
      console.log("rrooow", row)
      console.log("this.edeited", this.editedStep.fixtureTag)
      // let arrayFixture = []
      // let tempArray = []
      // let session_id = this.$route.params.session_id
      // let isSessionId = false
      // if (this.editedStep.fixture) {
      //   if (localStorage.getItem("fixture")) {
      //     arrayFixture = JSON.parse(localStorage.getItem("fixture"))
      //     arrayFixture.map((item, id) => {
      //       if (item.session_id === session_id) {
      //         tempArray = item.session_steps
      //         tempArray.push({
      //           "step_id": row.$id,
      //           "fixtureTag": this.editedStep.fixtureTag
      //         })
      //         item.session_steps = tempArray
      //         isSessionId = true
      //       }
      //     })
      //     if(!isSessionId) {
      //       arrayFixture.push({
      //         "session_id": session_id,
      //         "session_steps": [{
      //           "step_id": row.$id,
      //           "fixtureTag": this.editedStep.fixtureTag
      //         }]
      //       })
      //     }
      //     console.log("arrayfixture", arrayFixture)
      //     localStorage.setItem("fixture", JSON.stringify(arrayFixture))
      //   } else {
      //     arrayFixture.push({
      //       "session_id": session_id,
      //       "session_steps": [{
      //         "step_id": row.$id,
      //         "fixtureTag": this.editedStep.fixtureTag
      //       }]
      //     })
      //     console.log("arrayfixture", arrayFixture)
      //     localStorage.setItem("fixture", JSON.stringify(arrayFixture))
      //   }
      // }

    },
    async addTestStepAction() {
      // const _mess = this.processCheck();
      // console.log(_mess)
      // if (_mess !== false) {
      //   return this.$notify({
      //     type: "danger",
      //     message: _mess,
      //   });
      // }
      console.log(`STEP`, this.editedStep)
      this.stepLoading = true;
      if (this.editedStep.action === 'Put' || this.editedStep.action === 'Post' || this.editedStep.action === 'Get' || this.editedStep.action === 'Delete' || this.editedStep.action === 'playwright') {
        this.editedStep.selectorType = 'api'
        this.editedStep.selector = this.editedStep.command
        this.editedStep.inputValue = null

      }
      else if (this.editedStep.action === 'command') {
        this.editedStep.selectorType = 'command'
        this.editedStep.selector = this.editedStep.command
        this.editedStep.inputValue = null
      }

      if (this.editedStep.APIMockResponse !== '' && this.editedStep.fixtureTag !== '' && this.editedStep.customjson) {
        this.editedStep.customjson["APIMockResponse"] = this.editedStep.APIMockResponse
        this.editedStep.customjson["tags"] = this.editedStep.fixtureTag
      } else if (this.editedStep.APIMockResponse !== '' && this.editedStep.APIMockResponse && this.editedStep.customjson) {
        this.editedStep.customjson["APIMockResponse"] = this.editedStep.APIMockResponse
      } else if (this.editedStep.fixtureTag !== '' && this.editedStep.customjson) {
        this.editedStep.customjson["tags"] = this.editedStep.fixtureTag
      }

      if (!this.editedStep.fixture && this.editedStep.customjson) {
        this.editedStep.customjson["tags"] = null
      }
      let data = Object.assign({}, this.editedStep);
      let payload = undefined;
      let session_id = this.$route.params.session_id;
      let projectId = this.$route.params.projectId;
      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/session/v1/steps/${projectId.replace(/%20/g, '').replace(/\s/g, '')}/${session_id.replace(/%20/g, '').replace(/\s/g, '')}`;

      // handle missing arguments
      if (this.editedIndex < 0) {
        payload = { newTestSteps: [this.editedStep] };
        console.log({ payload });
        if (payload.newTestSteps[0].selector === undefined) {
          payload.newTestSteps[0]["selector"] = null;
        }
        if (payload.newTestSteps[0].selectorType === undefined) {
          payload.newTestSteps[0]["selectorType"] = null;
        } else {
          if (payload.newTestSteps[0]["selectorType"])
            payload.newTestSteps[0]["selectorType"] = payload.newTestSteps[0]["selectorType"].toLowerCase()
        }
        if (payload.newTestSteps[0].inputValue === '') {
          payload.newTestSteps[0]["inputValue"] = null;
        }

        if (payload.newTestSteps[0].insertItem === "after") {
          console.log("after", payload.newTestSteps[0])
          let isEntered = false
          this.toShow.map((item, index, array) => {
            if (item.stepId === payload.newTestSteps[0].targetStepId && !isEntered) {
              if (index + 1 >= array.length) {
                isEntered = true
                // payload.newTestSteps[0].targetStepId = array[index].stepId
                payload.newTestSteps[0].targetStepId = parseInt(this.currentPage) * index + 2
                // console.log("targetStepId: ", payload.newTestSteps[0].targetStepId)
                payload.newTestSteps[0].stepId = payload.newTestSteps[0].targetStepId
              } else {
                isEntered = true
                payload.newTestSteps[0].stepId = array[index + 1].stepId
                payload.newTestSteps[0].targetStepId = payload.newTestSteps[0].stepId
              }
            }

          })
        } else
          if (payload.newTestSteps[0].insertItem === "before") {
            payload.newTestSteps[0].insertItem = 'select current step'
          }

        // figure out the targetStepId based on the value in the add-form
        // payload.newTestSteps[0]["targetStepId"] = payload.newTestSteps[0]["stepId"];
        try {
          console.log("PAYLOAD", payload)
          console.log("URI", uri)
          const res = await axios.post(uri, payload);
          console.log("RES", res)
          if (res.status === 200) {
            this.addEditStep = false;
            this.$notify({
              type: "success",
              message: res.data.message,
            });
            this.adding = false
            this.editedStep = Object.assign({}, this.newStep);
            await this.getTestRunSteps();
          } else {
            this.$notify({
              type: "danger",
              message: `An error has ocurred:`,
            });
            this.adding = false
          }
        } catch (error) {
          console.log(error)
          this.$notify({
            type: "danger",
            message: `An error has ocurred: ${res.response.data.message}`,
          });
          this.adding = false
        }
      } else {
        data.op = "edit";
        data.stepId = data.targetStepId;
        delete data.targetStepId;
        payload = { stepEdits: [data] };
        payload.stepEdits[0]['insertItem'] = null

        if (payload.stepEdits[0].selector === undefined) {
          payload.stepEdits[0]["selector"] = null;
        }
        if (payload.stepEdits[0].selectorType === undefined) {
          payload.stepEdits[0]["selectorType"] = null;
        } else {
          if (payload.stepEdits[0]["selectorType"])
            payload.stepEdits[0]["selectorType"] = payload.stepEdits[0]["selectorType"].toLowerCase()
        }
        if (payload.stepEdits[0].inputValue === '') {
          payload.stepEdits[0]["inputValue"] = null;
        }
        if (payload.stepEdits[0].command === '') {
          payload.stepEdits[0].command = null
        }

        console.log("payload", payload)

        const res = await axios.put(uri, payload);
        try {
          if (res.status === 200) {
            this.addEditStep = false;
            this.$notify({
              type: "success",
              message: "This step is edited successfully",
            });
            this.selectedItemId = null
            this.editedStep = Object.assign({}, this.newStep);
            await this.getTestRunSteps();
          } else {
            this.$notify({
              type: "danger",
              message: `An error has ocurred: ${res.response.data.message}`,
            });

          }

        } catch (error) {
          this.$notify({
            type: "danger",
            message: `Cannot Edit Step`,
          });
          this.stepLoading = false;
        }

      }

      this.editedStep = Object.assign({}, this.newStep);
      this.selectedItemId = null;
      this.stepLoading = false;
    },
    async deleteTestStep(row) {
      let session_id = this.$route.params.session_id;
      let projectId = this.$route.params.projectId;
      let stepId = row.stepId
      const params = new URLSearchParams();
      params.append("step_uid", stepId);
      const confirmation = await swal.fire({
        title: "Run step: '" + row.stepNumber + ". " + row.stepDescription + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (confirmation.value === true) {
        try {
          await axios
            .delete(
              process.env.VUE_APP_API_URL_PREFIX +
              `/pageviews/session/v1/steps/${projectId.replace(/%20/g, '').replace(/\s/g, '')}/${session_id.replace(/%20/g, '').replace(/\s/g, '')}?step_uid=${stepId}`
            )
            .then((response) => {
              if (response.data.status === "success") {
                this.$notify({
                  type: "success",
                  message: `${response.data.message}`,
                });
                this.getTestRunSteps();
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `Delete Unsuccessful`,
          });
        }
      }
    },
    async getTestRunSteps() {
      console.log("apicall", this.items)
      if (this.$route.params.projectId) {
        if (this.$route.params.session_id) {
          const params = new URLSearchParams();
          let session_id = this.$route.params.session_id;
          let projectId = this.$route.params.projectId;
          //   params.append("selectorDetails", 1);
          //   params.append("selectorScreenshots", 1);
          // params.append("project_id", projectId);
          // params.append("session_id", this.id);
          params.append("page", this.currentPage);
          params.append("count", this.perPage);


          const uri = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/session/v1/steps/${projectId.replace(/%20/g, '').replace(/\s/g, '')}/${session_id.replace(/%20/g, '').replace(/\s/g, '')}?${params}`;
          const res = await axios.get(uri);

          if (res.status === 200) {
            this.total = res.data.totalSteps;
            this.to = this.perPage;
            this.toShow = res.data.response.map((elt, id) => {
              return { ...elt, $id: id, showSelector: false };
            });
            // if(localStorage.getItem("fixture")) {
            //   this.getFixtureTag()
            // }
          } else {

          }
        }
        this.requestingForData = false;
        this.startLoading = false;
      }

    },
    async getDetailProject() {
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };

      let projectId = this.$route.params.id;

      const url = `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1/${projectId}`;
      // check if the project contains mobile screenshots

      const resp = await axios.get(url);
      if (resp.data && resp.data.status === "success") {
        this.projectDetail = this.$route.query.wring
          ? { ...resp.data.response }
          : resp.data.response;
        return this.projectDetail.customjson !== null ?
          this.projectDetail.customjson.tags ?
            this.projectDetail.customjson.tags
            : null
          : null
      }
    },
    async getFixtureTag() {
      let tempList = []
      let listAppTags = []
      let temp = null
      localStorage.getItem("fixture") ?
        tempList = JSON.parse(localStorage.getItem("fixture"))
        : tempList = null
      if (localStorage.getItem("fixtureApp")) {
        listAppTags = JSON.parse(localStorage.getItem("fixtureApp"))
      } else if (this.toShow.customjson !== null) {
        let tempArray = await this.getDetailProject()
        listAppTags = {
          fixtureTags: tempArray,
          projectId: this.$route.params.id
        }
        localStorage.setItem(
          "fixtureApp",
          JSON.stringify({
            fixtureTags: tempArray,
            projectId: this.$route.params.id
              .replace(/%20/g, "")
              .replace(/\s/g, ""),
          })
        )
      }

      // if(tempList !== null) {
      //   const uniqueTags = new Set();

      //   tempList.forEach(item => {
      //     if(item.projectId === this.$route.params.id.replace(/%20/g, '').replace(/\s/g, '')) {
      //       item.session_tags.forEach(tag => {
      //         uniqueTags.add(tag.fixtureTag);
      //       });
      //     }

      //   });

      //   const listTags = Array.from(uniqueTags).map(tag => ({
      //     value: `call fixture ${tag}`,
      //     label: tag
      //   }));
      //   this.listFixtureTags.push({
      //     options: listTags.length > 0 ? listTags : null
      //   })
      // }

      if (listAppTags !== null) {
        const uniqueTags = new Set();
        console.log("listFixtureTags", this.listFixtureTags)
        if (listAppTags.projectId.includes(this.$route.params.id.replace(/%20/g, '').replace(/\s/g, ''))) {
          listAppTags.fixtureTags.map(item => {
            if (this.listFixtureTags.length > 0) {
              this.listFixtureTags[0].options.push({
                value: `call fixture ${item}`,
                label: item
              })
            } else {
              this.listFixtureTags.push({
                options: []
              })
              this.listFixtureTags[0].options.push({
                value: `call fixture ${item}`,
                label: item
              })
            }
          })

        }

      }

      console.log("listFixtureTags", this.listFixtureTags)
    },

    async previewPictureWithoutSelector(src) {
      this.viewPicture = true;
      this.isMultiple = false;
      this.testStepBigSS = src;
    },
    async previewPicture(src, row) {
      this.testStepBigSS = "";
      this.viewPicture = true;
      let requestType = "";
      let selectorType = "";
      this.isMultiple = false;
      this.testStepBigSS = src;
      this.loading = false;
    },

    //#endregion
  },

  computed: {
    imageSrc() {
      return this.$store.state.imageSrc
    },
    modalTitle() {
      return this.editedIndex === -1
        ? "Add Step"
        : "Edit the step"
    },
    modalActionText() {
      return this.editedIndex === -1
        ? this.$t("test_run_steps.save")
        : this.$t("test_run_steps.update");
    },
  },

  async created() {
    if (window.window.innerWidth >= 801) {
      this.dataView =
        sessionStorage.getItem("test-data-view") !== null
          ? sessionStorage.getItem("test-data-view")
          : "list";
    } else {
      this.dataView = "card"
    }
    if (localStorage.getItem('wring-user-info')) {
      this.userName = JSON.parse(localStorage.getItem("wring-user-info"))
    }
    await Promise.all([this.getTestRunSteps()]);
    this.getFixtureTag()
    const walToken = localStorage.getItem("vue-authenticate.access_token");
    this.isSharing = localStorage.getItem("vue-authenticate.is_sharing");
    this.sharedJSON = Object.assign({}, this.parseJwt(walToken));
    this.namePage = this.$route.params.nameProject
    this.projectId = this.$route.params.projectId
  },
};
</script>

<style lang="scss" scoped>
.swal2-popup #swal2-title {
  font-size: 24px !important;
}
.add_button:focus {
  color: white !important;
}
.add_button:hover {
  background-color: var(--main-blue-color) !important;
}
.edit-message-footer {
  justify-content: space-between;
  .right-btns {
    display: flex;
    
  }
}

#area-edit {
  background-color: #f4f4f4;;
}
#message-edit {
  min-height: 100% !important;
  overflow: auto;
  font-size: 13px;
}
#area-edit::placeholder{
  color: black !important;
}
/* Blinking cursor effect */
.typing-animation::after {
    content: '|';
    animation: blink 1s steps(2, start) infinite;
    color: black;
    font-weight: bold;
    display: inline-block;
    margin-left: 2px;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


</style>

<style lang="scss" scopped>
$pColor: #5e72e4;
$txtColor: #4b4b71;


.prg-modal{
  width: 100%;
  height: 100%;
  .el-progress-circle {
    height: 126px !important;
    width: 200px !important;
    margin: 5rem auto;
  }
}

.container--buttons {
  div {
    .modal-dialog {
      max-width: 75vw !important;
    }
    .edit-message-body {
      position: relative;
      height: 385px;
      .el-input__inner:nth-child(1) {
        width: 35%;
        
      }
      .el-input__inner {
        height: 100%;
      }
    }
  }
}

.container-prompt {
  position: absolute;
  bottom: 0px;
  border-radius: 10px;
  width: 298px;
  background: inherit;
  .btn-option--prompt {
    border: none;
    background: inherit;
    cursor: pointer;
    display:grid;
    font-size: 8px;
    transition: 0.3s;
    font-weight: 400;
  }
  .btn-option--prompt:hover {
    color: blue
  }
}

.left-sbmt {
  display: flex;
  .clear_btn-sp {
    margin-left: 10px;
  }
}

.gen-test-modal {
  position: absolute;
  padding: 20px;
  width: 900px;
  height: 500px;
  top: 15%;
  z-index: 1000;
  left: 15%;
  background-color: white;
  border-radius: 10px;

  .el-textarea {
    height: 87%;

    .el-textarea__inner {
      height: 90% !important;
    }
  }
}

#stepInputPrompt {
  background: var(--input-default-bg);
  border: 1px solid var(--input-default-bg);
  font-size: 15px;
  color: black;
  font-weight: 400;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.gen-test--title {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}

.btn-gen--download {
  display: flex
}

.btns-download {
  display: flex;

  div {
    margin-top: 1.5rem;
    margin-left: 4rem;
  }

  .el-switch.is-checked>.el-switch__core::after {
    margin-left: -27px
  }
}

.icon-views {
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 10px;
  background-color: #D6D6D6;
  border-radius: 10px;
}

.step-description span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-dropdown-popup .el-popper {
  width: 200px !important;
}

.cstm-form--insert {
  margin-left: 35%;
}

.el-col-18,
.el-col-6 {
  width: 100%;
}

.edt-desc {
  textarea {
    border-radius: 10px;

  }
}



.cstm-dropdown--download {
  position: absolute;
  /* Absolute positioning relative to the container */
  top: 18% !important;
  /* Position below the trigger element */
  left: 47% !important;
  /* Default position for larger screens */
  padding: 15px !important;
  border-radius: 10px !important;
  width: 350px !important;
  /* Set the width to auto to avoid stretching */
  background-color: #fff;
  /* Background color of the dropdown */
  border: 1px solid #ccc;
  /* Border around the dropdown */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Adds a subtle shadow to the dropdown */
  z-index: 1000;
  /* Ensure the dropdown appears above other elements */
  box-sizing: border-box;
  /* Ensure padding and border are included in the element's total width and height */
}

.prompt-download:hover {
  background-color: inherit !important;
}

.prompt-download {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.title-download {
  margin-bottom: 0px;
  font-size: 1rem;
}

.cstm-dropdown--download .custom--dropdown--item {
  width: 200px !important;
  border-radius: 10px;

  label {
    span>h2 {
      color: var(--text-link, #53568A);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-left: 12px;
      margin-bottom: 0px !important;
    }

    label:hover {
      width: 240px !important;
    }

    span {
      display: flex;
      align-items: center;
    }

    display: flex;
    width: 318px !important;
    border-radius: 10px;
    background: var(--bg-island-main, #E6E6E6);
    margin-bottom: 2px;
    align-items: center;
  }
}

/* Adjustments for smaller screens */
@media (max-width: 1400px) {
  .cstm-dropdown--download {
    left: 900px !important;
    /* Adjust position for screens narrower than 1400px */
  }
}

@media (max-width: 1200px) {
  .cstm-dropdown--download {
    left: 700px !important;
    /* Adjust position for screens narrower than 1200px */
  }
}

@media (max-width: 1000px) {
  .cstm-dropdown--download {
    left: 500px !important;
    /* Adjust position for screens narrower than 1000px */
  }
}

@media (max-width: 800px) {
  .cstm-dropdown--download {
    left: 300px !important;
    /* Adjust position for screens narrower than 800px */
  }
}

@media (max-width: 600px) {
  .cstm-dropdown--download {
    left: 100px !important;
    /* Adjust position for screens narrower than 600px */
  }
}

@media (max-width: 400px) {
  .cstm-dropdown--download {
    left: 10px !important;
    /* Adjust position for screens narrower than 400px */
  }
}


.cstm-dropdown--download .icon {
  width: 10px;
  /* Adjust the size of the icons */
  height: 10px;
  /* Adjust the size of the icons */
}

.tab--btn {
  padding: 0.8rem 0.6rem;
  border: none;
  border: solid 0.1px rgba($pColor, 0.3);
  color: rgba($pColor, 0.8);
  background: transparent;

  span {
    font-size: 0.9rem !important;
    font-weight: 600;
    color: rgba($pColor, 0.8);
  }

  &:hover {
    background: rgba($pColor, 0.05);
  }

  &.active {
    border-color: var(--main-blue-color);
    background: var(--main-blue-color);
    color: #ffffff;

    span {
      color: #ffffff;
    }
  }

  &.secondary {
    border: solid 0.1px rgba(gray, 0.3);
    color: rgba(gray, 0.8);

    span {
      color: gray;
    }

    &:hover {
      background: rgba(gray, 0.05);
    }

    &.active {
      background: var(--main-blue-color);
      color: #ffffff;

      span {
        color: #ffffff;
      }
    }
  }
}

.expendit {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  width: fit-content;
}

.custom-card-details {
  padding: 0;
}

.text-btns-container-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(171, 171, 171);
  padding: 5px 0 5px 5px;
}

.options-btn-step {
  height: 40px;
  margin-top: 10px !important;
}

.loading-card-step-list {
  background-color: var(--primary-bg);
}

.mod--content .el-form-item .el-input input {
  height: 43px !important;
}

.cursor--zoom {
  cursor: zoom-in;
}

.photo-modal .modal-dialog .modal-content {
  width: 70% !important;
  text-align: center !important;
  margin: 0 auto !important;
}

@media (max-width: 535px) {
  .adpt--page-details {
    display: none !important;
  }
}

.video-modal-container .modal-dialog-centered {
  max-width: 100% !important;
  width: 100% !important;
  padding: 0 !important;
}

.video-modal-container .modal-dialog .modal-content {
  text-align: center !important;
  margin: 0 auto !important;
  width: 90%;
}

.video-modal-container .modal-dialog .modal-content .modal-header .close span {
  font-size: 3rem !important;
}

.video-modal-container .modal-dialog .modal-body {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.request-data-loading {
  display: flex;
  justify-content: center;
}

.p--img {
  width: 100%;
}

.trsl--steps>.list-group>.custom-trsl--parent {
  height: 50vh !important;
}


.italic {
  font-style: italic;
}

.text-small {
  font-size: 0.85rem;
}

.bold {
  font-weight: 600;
  line-height: 0.9rem;
}

.el--flex {
  align-items: center;
}

.trs--container {
  padding: 10px 0;
}

.step-fixture {
  width: 10%;
}

.container-expand {
  width: 100%;

  .el-collapse-item>div:nth-child(1) {
    width: 200px;
  }

  .el-collapse-item__header {
    padding: 10px;
    border-radius: 10px;

  }
}

.btn--container {
  margin: 20px 0;
  height: 70px;

  .btn--content {
    float: right;
    border: solid 0.1px rgba($pColor, 0.3);

    .el-card__body {
      padding: 10px !important;

      * {
        font-size: 0.8rem !important;
      }
    }
  }
}

.elt--modal_img {
  cursor: zoom-in;
  width: 200px;
  border: 1px solid #1F213E;
  margin: 0 auto;
  border-radius: 10px;
}



.container-modal-screen {
  margin: 10px;
  display: flex;
  width: 100%;
}

.cstm-insert {
  margin: 5px;
  width: 13% !important;
}

.el-insert-item__label {
  margin: 12px 0 5px 0 !important;
  color: var(--main-blue-color) !important;
  font-size: 17px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.trs--list {
  .trsl--elt {
    margin: 10px 0;
    cursor: grab;

    p {
      font-weight: normal;
      //line-height: 1.5rem;
    }

    .trsl--img {
      img {
        max-width: 100%;
        display: inline-block;
        border-radius: 0.3rem;
        cursor: zoom-in;
      }
    }

    .trsl--txt {
      margin-top: 20px;
    }

    .trsl--btn_container {
      display: flex;
      align-items: center;
      justify-content: center;

      .el-button+.el-button {
        margin-left: 0 !important;
      }

      button {
        width: 70px;
        padding: 8px;
        font-size: 0.7rem;
      }
    }
  }
}

.custom-trsl--parent {
  overflow-y: scroll;
  height: 40vh;
}

.custom-trsl--parent .el-card__body {
  background-color: #E6E6E6;
  padding-top: 5px;
  padding-bottom: 5px;
}

.enable-switch-cont .el-form-item__content {
  margin: 10px 0 10px 0;
  align-items: center;
  display: flex;
}

.enable-switch-cont .el-switch {
  margin-left: 12px;
}

.enable-switch-cont {
  margin-bottom: 0;
}

.edit-body-modal-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 22px;
}

.edit-body-modal-grid .el-form-item {
  margin-bottom: 0;
}

.edit-body-modal-grid .el-form-item__label {
  margin: 12px 0 5px 0 !important;
  color: var(--main-blue-color) !important;
  font-size: 17px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.custom-trsl--parent .el-card {
  background-color: #E6E6E6;
  border-bottom: 1px solid rgba(82, 95, 127, .25);
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-radius: 0;
}

.elt--banner_overlay_fixture {
  position: absolute;
  top: 55px;
  right: 100px;
  max-width: 100px;
  text-align: center;
}

.mod--content {
  .el-form-item {
    label {
      margin: 0;
      color: rgba(black, 1);
      font-weight: 700;
    }

    .el-input {
      input {
        padding-top: 12px;
        padding-bottom: 12px;
        border: 1px solid #DBDBDB;
      }

      &.no-border {
        input {
          border: none;
          color: black;
          font-weight: 600;
        }
      }
    }

    .el-textarea {
      textarea {
        border: 1px solid #CCC;
        background-color: #CCC;
        color: #6E6E6E;
      }

      &.no-border {
        textarea {
          border: none;
          color: $txtColor;
          font-weight: 600;
        }
      }
    }
  }

  &.lite {
    label {
      font-weight: 600;
      font-size: 0.7rem;
      line-height: unset;
    }

    .el-input {
      input {
        font-size: 0.75rem;
        border-color: rgba($txtColor, 0.3);
      }
    }

    .el-textarea {
      textarea {
        font-size: 0.75rem;
        border-color: rgba($txtColor, 0.3);
      }
    }
  }
}

.step-description {
  max-width: 80%;
  margin-bottom: 1.2rem;
  overflow-x: hidden;
  display: flex;
  cursor: text;
}

.edit-desc {
  width: 100%;
  display: flex;
  position: relative;

  .submit-edit--desc {
    background-color: inherit;
    position: absolute;
    right: 10px;
    padding: 0px;
    top: 25%;
    border-radius: 50px;
  }

  .submit-edit--desc:hover {
    background-color: inherit;
    border: none;
  }

  .submit-edit--desc:focus {
    background-color: inherit;
    border: none;
  }
}

.step-description span {
  padding-top: 5px;
  word-break: normal;
  white-space: nowrap;
  font-size: 14px;
  color: var(--main-blue-color);
  font-weight: 400;
  // display: block;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // width: calc(80% - 10px);
}

// .step-description span:hover {
//   overflow: visible;
//   display: inline-block;
//   white-space: normal;
//   text-overflow: clip;
// }

.ellipsis-dots {
  cursor: pointer;
  margin-left: 0.5rem;
  color: red !important;
  font-weight: bold;
}

.only-selector {
  display: inline-block;
  width: 100%;
  overflow-y: hidden;
  margin-left: 0.5rem;

  .mark {
    background: rgba(#5f72e4, 0.15);
    border: solid 0.1px rgba(#5f72e4, 0.3);
    color: #5f72e4;
    border-radius: 0.15rem;
    font-size: 0.65rem;
    padding: 0.3rem 0.5rem;
    cursor: pointer;
  }

  .empty--selector {
    color: var(--main-blue-color);
    font-size: 1.2rem;
    text-decoration: underline;
    cursor: help;
  }
}

.modal-body {
  height: 60vh;
  overflow-y: scroll;
}

.container-download {
  position: relative;

  .el-progress {

    /* position: absolute;
    top: -5px;
    left: -5px;
    z-index: 9999; */
    .el-progress-circle {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.el-button--primary.is-plain,
.el-button--success.is-plain,
.el-button--danger.is-plain,
.el-button--primary.is-plain:hover,
.el-button--success.is-plain:hover,
.el-button--danger.is-plain:hover,
.el-button--primary.is-plain:focus,
.el-button--success.is-plain:focus,
.el-button--danger.is-plain:focus {
  background-color: #D6D6D6;
  font-size: 20px !important;
  font-weight: 600;
  color: var(--main-blue-color) !important;
  border: 1px solid #D6D6D6;
}

.edit-accordion {
  margin-block: 10px;
}

.edit-accordion-btns {
  gap: 5px;
  display: inline-flex;
}

.left-footer .modal-footer {
  align-items: start;

  justify-content: start;
}

.edit-accordion-btns {
  margin-top: 0;
}
@media (max-width: 1350px) {
  .container-prompt {
    .btn-option--prompt:nth-child(3) {
      width: 74%;
    }
  }
}
</style>